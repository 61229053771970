import { startSpinner } from './spinner';
import { asyncReset } from './errors';
import { ActionCreatorType } from '../types';

/** Dispatch functions before an async call
 ** SID: N/A because general
*/
export const asyncLoading = (): ActionCreatorType => dispatch => {
    dispatch(startSpinner());
    dispatch(asyncReset());
}
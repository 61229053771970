// View: React and Router-DOM
import React, { useState } from 'react';
// Style
import './useSlider.scss';

type UserSliderPropsType = {
    initialWidth: number,
    isAllTransparent?: boolean,
}


/** Returns : (1) a function that creates a slider jsx and (2) the width controlled by the slider
 * number (1) might create a perfomance problem because it returns a new function everytime the hook changes. So far, it looks fine.
** SID: ?
 * @author Nassir Al-Khishman 
*/
export const useSlider = ({ 
        initialWidth,
        isAllTransparent
    }: UserSliderPropsType) => {
    
    const [width, setWidth] = useState(initialWidth);
    const [mpos, setMpos] = useState<number | null>(null);

    const resize = (e: MouseEvent | TouchEvent, initialMpos: number) => {
        let mPosBeforeMoving;
        if (mpos !== null) {
            mPosBeforeMoving = mpos;
        } else {
            mPosBeforeMoving = initialMpos;
        }

        let mPosCurrent;
        if (e instanceof MouseEvent) {
            mPosCurrent = e.x
        } else if (e instanceof TouchEvent) {
            mPosCurrent = e.touches[0].clientX // mobile support
        } else {
            return
        }

        const dx = mPosBeforeMoving - mPosCurrent;
        setMpos(mPosCurrent);

        const newWidth = (width - dx);
        setWidth(newWidth)
    }

    const handleClickSlider = (e: React.MouseEvent<HTMLDivElement>) => {
        const initialMpos = e.nativeEvent.clientX;
        const initiateResize = (e: MouseEvent) => resize(e, initialMpos);

        const removeMouseMoveListener = () => {
            // need to reset Mpos so that resize does not use stale mPos (for example in the table after adding more columns)
            setMpos(null)
            document.removeEventListener("mousemove", initiateResize, false);
        }
        document.addEventListener("mouseup", removeMouseMoveListener, false);

        document.addEventListener("mousemove", initiateResize, false);
    }

    const handleTouchSlider = (e: React.TouchEvent<HTMLDivElement>) => {
        const initialMpos = e.nativeEvent.touches[0].clientX;
        const initiateResize = (e: TouchEvent) => resize(e, initialMpos);

        const removeTouchMoveListener = () => {
            // need to reset Mpos so that resize does not use stale mPos (for example in the table after adding more columns)
            setMpos(null)
            document.removeEventListener("touchmove", initiateResize, false);
        }
        document.addEventListener("touchend", removeTouchMoveListener, false);

        document.addEventListener("touchmove", initiateResize, false);
    }

    const sliderStyle = {
        "cursor": "w-resize"
    }

    const lineClass = "slider" + (isAllTransparent ? null : " bg-alternative");

    const ui = () => (
        <div 
            style={sliderStyle}
            className="slider-bulk"
            onMouseDown={handleClickSlider} 
            onTouchStart={handleTouchSlider} // for mobiles
            role="presentation"
        >
            <div className={lineClass}
                style={sliderStyle}
            >
            </div>
        </div>
    )

    return {
        width: width,
        ui: ui
    }
}

export default useSlider
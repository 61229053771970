// View: React
import { useEffect } from 'react';
// State: React-Redux
import { useSelector } from 'react-redux';
// Constants
import { SITE_NAME } from '../../globalConstants';
import { pageMetaDescriptionId } from './PageMetaDescription';
import { ReduxState } from '../../reducers';


const TITLE_BLINK_TIME = 3000;


/** Set change page title (and meta description if applicable) upon navigation
 ** SID: 0,012 Navigation
 * @author Nassir Al-Khishman 
*/
export const PageSpecificMeta = () => {
 
    /** Redux: Flash the number of unread messages in the title.*/
    const unreadMessagesCount = useSelector((state: ReduxState) => state.messenger.unreadMessagesCount);


    const title = document.getElementById('webpage-title') as HTMLTitleElement;

    useEffect(() => {
        const changeMeta = () => {

            // title
            const heading = document.querySelector("h1");
            if ((heading != null) && (heading.textContent !== SITE_NAME)) {
                const pageSpecificTitle = heading.textContent;
                title.textContent = [pageSpecificTitle, SITE_NAME].join(' | ').trim();
            }

            // meta description
            const pageSpecificMeta = document.getElementById(pageMetaDescriptionId)
            if (pageSpecificMeta != null) {
                const mainMeta = document.getElementById('meta-description') as HTMLMetaElement;
                const metaDescriptionText = pageSpecificMeta.textContent? pageSpecificMeta.textContent: "";
                mainMeta.setAttribute("content", metaDescriptionText);
            }
        };

        // Select the node that will be observed for mutations
        const body = document.querySelector("body") as HTMLBodyElement;
        const config = { attributes: true, childList: true, subtree: true };
        const observer = new MutationObserver(changeMeta);

        observer.observe(body, config);

        return function cleanup() {
            observer.disconnect()
        }
    }, [title])

    // make title responsive to number of unread messages
    useEffect(() => {

        const normalTitle = title.textContent;
        let intervalID: number;

        if (unreadMessagesCount > 0) {
            const titleWithMessage = `${unreadMessagesCount} unread message(s) | ${SITE_NAME}`

            title.textContent = titleWithMessage;

            let intervalCounter = 0;
            intervalID = window.setInterval(() => {
                intervalCounter++;
                if (intervalCounter % 2 === 0) {
                    title.textContent = normalTitle;
                } else {
                    title.textContent = titleWithMessage;
                }
            }, TITLE_BLINK_TIME)
        } else {
            title.textContent = normalTitle;
        }

        return () => clearInterval(intervalID)
    }, [title, unreadMessagesCount])

    return null
}

export default PageSpecificMeta
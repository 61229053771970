import { useEffect, useRef } from 'react';
import deepEqual from 'lodash.isequal'; 

function deepCompareEquals(a: any, b: any){
    return deepEqual(a, b);
}
  
function useDeepCompareMemoize(value: any) {
    const ref = useRef() 
    // it can be done by using useMemo as well
    // but useRef is cleaner and easier

    if (!deepCompareEquals(value, ref.current)) {
        ref.current = value
    }

    return ref.current
}

/** useEffect with a deep comparison
 // ! Eslint will not alert you of missing independencies
 */
export function useDeepCompareEffect(callback: () => void, dependencies: any[]) {
    useEffect(  // eslint-disable-line
        callback,
        dependencies.map(useDeepCompareMemoize)
    )
}
import React from 'react';

export const pageMetaDescriptionId = "page-meta-description";


type PageMetaDescriptionPropsType = {
    description: string
}

const PageMetaDescription = ({
        description
    }: PageMetaDescriptionPropsType) => {

    return (
        <span hidden id={pageMetaDescriptionId} className="d-none">{description}</span>
    )
}

export default PageMetaDescription
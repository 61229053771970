import { createAlert, createDropdownError } from './alerts_dropdown';
import { asyncLoading } from './combinations';
import { stopSpinner } from './spinner';
import { preprocessBackendErrors } from '../utils/preprocessBackendErrors';
import {
    LOGOUT_SUCCESS,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    INITIATE_LOGIN_SUCCESS,
    INITIATE_LOGIN_FAIL,
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    LOGOUT_IS_OLD,
} from './types';
import type { ActionCreatorType, HandleBackendErrorsType, HandleBackendSuccessType, UserTypeType } from '../types';

import LogRocket from 'logrocket';
import { SUPERVISOR_PAGE_PREFIX, SOCIAL_LOGGED_KEY } from '../globalConstants';
import { ReduxState } from '../reducers';
import { appendMainAlert } from './main_alert';
import { Link } from 'react-router-dom';
import ListSentence from 'src/components/list_handlers/ListSentence';
import axios from 'axios';



type ConfigType = {
    headers: {
        [key:string]: string
    }
}
export const DEF_CONFIG = (): ConfigType => {
    return {
        headers: {
            'Content-Type': 'application/json',
            // need to include 'authorization here to prevent axios from sending old auth headers
            'Authorization': ''
        }
    }
}

/** Gets token from reduxand returns it within a config
 ** SID: 0,005 Security
*/
export const tokenConfig = (getState: () => ReduxState) => {
    // Headers
    let config = DEF_CONFIG();
    
    // Get token from state
    const token = getState().authenticationBasic.token;

    // If token, add to header
    // otherwise, the request will fail because it requires authentication
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }

    return config
}


type CheckUserDataType = {
    email: string;
}
export type CheckUserType = (
        data: CheckUserDataType,
        handleBackendErrors: HandleBackendErrorsType
    ) => ActionCreatorType
/** Search backend for email.
 ** SID: 0,003
*/
export const checkUserThunk: CheckUserType = (
        data,
        handleBackendErrors
    ) => async (dispatch) => {
    dispatch(asyncLoading());
    const email = data.email;

    const source = axios.CancelToken.source();

    axios.get(`/api/users/check-user?email=${email}`, DEF_CONFIG())
        .then(res => {
            dispatch({
                type: INITIATE_LOGIN_SUCCESS,
                payload: res.data.user.email
            });
        })
        .catch(err => {
            dispatch({ type: INITIATE_LOGIN_FAIL });
            dispatch(preprocessBackendErrors(handleBackendErrors, err))
        })
        .finally(() => dispatch(stopSpinner()))
    
    return source 
}
// type CheckUserType = typeof checkUserThunk

/**  Remove email from state incase user accidentally initiated current login.
 ** SID: 0,003
*/
export const nullifyEmail = () => {
    return { type: INITIATE_LOGIN_FAIL };
}


interface LoadUserResponseType {
    user: {pk: string, email: string},
    user_type: UserTypeType,
    /** for students */
    cv_pending_access_list?: number[],
    /** for login */
    [key:string]: any
}
/** Get user details
 ** SID: 0,004
*/
export const loadUser = (): ActionCreatorType => async (dispatch, getState) => {

    const source = axios.CancelToken.source();
    if (getState().authenticationBasic.token !== null) {
        dispatch({ type: USER_LOADING });
        dispatch(asyncLoading());
        axios.get('/api/users/load', tokenConfig(getState))
            .then(res => {
                const { cv_pending_access_list, ...data } = res.data as LoadUserResponseType;
                dispatch({
                    type: USER_LOADED,
                    payload: data // TODO: compare this res.data to that from checkUser
                });
                if (data.user_type === 'student' && cv_pending_access_list) {
                    dispatch(setCvPendingAccessList(cv_pending_access_list))
                }
                if (process.env.NODE_ENV === 'production') {
                    LogRocket.identify(res.data.user.pk, {
                        name: data.user.email,
                        email: data.user.email,
                    })
                }
            })
            .catch(err => {
                dispatch({
                    type: AUTH_ERROR
                })
            })
            .finally(() => dispatch(stopSpinner()))
    } else {
        dispatch({
            type: AUTH_ERROR
        })
    }
    
    return source 
}


type LoginDataType = {
    email: string,
    password: string,
}
interface LoginResponseType extends LoadUserResponseType {
    token: string,
    log_to_howto: boolean,
}
/** Search backend for email.
 ** SID: 0,003
*/
export const login = (
        data: LoginDataType,
        handleBackendErrors: HandleBackendErrorsType,
        handleBackendSuccess: HandleBackendSuccessType,
    ): ActionCreatorType => async (dispatch) => {
    dispatch({ type: USER_LOADING });
    dispatch(asyncLoading());
    // Body
    const email = data.email;
    const password = data.password;
    const body = { email, password };

    const source = axios.CancelToken.source();

    axios.post('/api/users/login', body, DEF_CONFIG())
        .then(res => {
            // general login stuff
            dispatch(createAlert({ userLoggedIn: [`Login successful`] }));
            const { cv_pending_access_list, ...data } = res.data as LoginResponseType;
            if (data.user_type === 'student' && cv_pending_access_list) {
                dispatch(setCvPendingAccessList(cv_pending_access_list))
            }
            dispatch({ type: LOGIN_SUCCESS, payload: data });
            // inform user
            handleBackendSuccess(data);
            
            // logrocket
            if (process.env.NODE_ENV === 'production') {
                LogRocket.identify(res.data.user.pk, {
                    name: res.data.user.email,
                    email: res.data.user.email,
                })
            }
            // override social-logged incase someone on device previously logged in using google/fb
            localStorage.setItem(SOCIAL_LOGGED_KEY, 'false')
        })
        .catch(err => {
            dispatch({ type: LOGIN_FAIL })
            dispatch(preprocessBackendErrors(handleBackendErrors, err))
        })
        .finally(() => dispatch(stopSpinner()))
    
    return source 
}



/** Deletes token from backend and deletes authentication from redux and localstorage.
 ** SID: 0,003
*/
export const logout = (): ActionCreatorType => async (dispatch, getState) => {

    const source = axios.CancelToken.source();

    axios.post('/api/users/logout', null, tokenConfig(getState))
        .then(res => {
            dispatch(createAlert({ userLoggedOut: [`Logout successful`] }));
            dispatch({
                type: LOGOUT_SUCCESS
            });
        })
        .catch(err => {
            if (err.response != null) {
                if (err.response.data['detail'] == null) {
                    dispatch(createDropdownError("Logout was unsuccessful. Are you sure you're connected to the internet?"));
                } else if (err.response.data['detail'].toLowerCase().includes('invalid token')) {
                    dispatch(createAlert({ userLoggedOut: [`Logged out`] }));
                    dispatch({
                        type: LOGOUT_SUCCESS
                    });
                }
            }
        })
        .finally(() => {
            dispatch(stopSpinner());
            // set logout as old. This is required by PrivateRoute component
            setTimeout(() => {
                dispatch({
                    type: LOGOUT_IS_OLD
                });
            }, 5000)
        })
    
    return source 
};


/** Falsify log_to_howto in backend and logToHowTo in redux
*/
export const falsifyLogToHowTo = (): ActionCreatorType => async (dispatch, getState) => {
    const body = { log_to_howto: false };
    const userId = getState().authenticationBasic.userID;

    const source = axios.CancelToken.source();

    axios.put(`/api/gradsun/settings/${userId}/`, body, tokenConfig(getState))
        .then(res => {
        })
        .catch(err => {
            // do nothing
        })
    
    return source 

};


/** Delete user's account.
*/
export const deleteUser = (
        handleBackendErrors: HandleBackendErrorsType,
        handleBackendSuccess: HandleBackendSuccessType<undefined>,
    ): ActionCreatorType => async (dispatch, getState) => {
    dispatch(asyncLoading());

    const source = axios.CancelToken.source();

    axios.delete(`/api/users/delete-user/`, tokenConfig(getState))
        .then(res => {
            dispatch(createAlert({ userDeleted: [`Account deleted successfully`]}));
            dispatch({ type: AUTH_ERROR });
            handleBackendSuccess(undefined);
        })
        .catch(err => {
            dispatch(preprocessBackendErrors(handleBackendErrors, err));

        })
    
    return source 
}


const setCvPendingAccessList = (arr: number[]): ActionCreatorType => (dispatch, getState) => {
    const alertId = 'supervisors-pending-cv-access';
    if (arr.length > 0) {
        const mainAlert = (
            <p>
                {arr.length === 1? 'Supervisor ' : 'Supervisors '}
                <ListSentence
                    list={arr}
                >
                    {(el) => {
                        return (
                            <Link 
                                className="text-decoration-none"
                                to={SUPERVISOR_PAGE_PREFIX + `/${el}`}
                            >
                                {el}
                            </Link>
                        )
                    }}            
                </ListSentence>
                {arr.length === 1? ' is ' : ' are '}
                requesting access to your CV. Cancel or reject from your preferences page.
            </p>
        )
        dispatch(appendMainAlert({id: alertId, msg: mainAlert}))
    }
}
import React from 'react'
// Icons
import { ReactComponent as CheckmarkIcon } from 'bootstrap-icons/icons/check-circle-fill.svg';
import { ReactComponent as ExclamationIcon } from 'bootstrap-icons/icons/exclamation-diamond-fill.svg';
import { ReactComponent as InfoIcon } from 'bootstrap-icons/icons/info-circle-fill.svg';

import { ReactComponent as CloseIcon } from 'bootstrap-icons/icons/x.svg';

const buttonStyle = {
    marginLeft: '20px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: 'black'
}


type AlertTemplatePropsType = {
    message: string,
    options: { type: "success" | "error" | "info" },
    close: () => void
}

const AlertTemplate = ({
        message,
        options,
        close
    }: AlertTemplatePropsType) => {
    let type;
    if (options.type === 'success') {
        type = 'success';
    } else if (options.type === 'error') {
        type = 'danger';
    } else if (options.type === 'info') {
        type = 'info';
    }

    const alertClass = ['d-flex alert p-3', `alert-${type}`, `top-border-${type}`, 'shadow-low-offset'].join(' ').trim();

    return (
        <div className={alertClass} style={{"maxWidth": "50vw", "margin" : "1rem auto"}}>
            <div className="me-3">
                {options.type === 'info' && <InfoIcon />}
                {options.type === 'success' && <CheckmarkIcon />}
                {options.type === 'error' && <ExclamationIcon />}
            </div>
            <div>{message}</div>
            <button onClick={close} style={buttonStyle}>
                <CloseIcon />
            </button>
        </div>
    )
}

export default AlertTemplate
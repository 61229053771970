// View: React
import React from 'react';
// Components
import { BaseButton } from './BaseButton';
// Icons
import { ReactComponent as CloseIcon } from 'bootstrap-icons/icons/x.svg';
// Constants
import { ICON_SIZE_LARGE } from '../../globalConstants';
// Style
import './CloseButton.scss';

type CloseButtonPropsType = {
    /** Close the parent. */
    handleClose: () => void,
    ariaLabel: string,
    showText?: boolean
}
export const CloseButton = ( { handleClose, ariaLabel, showText = true }: CloseButtonPropsType) => {
    return (
        <div className="ms-auto">
            <BaseButton
                handleAction={handleClose}
                className="btn close-button"
                ariaLabel={ariaLabel}
                icon={<CloseIcon width={ICON_SIZE_LARGE} height={ICON_SIZE_LARGE}/>}
            >
                {showText ? 'Close' : undefined}
            </BaseButton>
        </div>
    )
}

export default CloseButton
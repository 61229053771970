import { MainAlertType } from 'src/reducers/main_alert';
import { REMOVE_MAIN_ALERT, APPEND_MAIN_ALERT } from './types';


/** Set the main alert
*/

export const appendMainAlert = (alert: MainAlertType) => {
    return {
        type: APPEND_MAIN_ALERT,
        payload: alert
    }
}

/** Set the main alert
*/
export const removeMainAlert = (id: string) => {
    return {
        type: REMOVE_MAIN_ALERT,
        payload: id
    }
}
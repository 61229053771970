// View: React and Router-DOM
import React from 'react'
// State: React-Redux
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import PrivateRoute, { PrivateRoutePropsType } from './PrivateRoute';


/** Encapsulate routes that require admin
 ** SID: 0,003 and 0,004
 ** Avoids redirect loops
 * @author Nassir Al-Khishman 
*/
const AdminRoute = ({
        pathDisplay = "that page",
        children,
        ...rest
    }: PrivateRoutePropsType) => {//component is within element tag

    const isStaff = useSelector((state: ReduxState) => state.authenticationBasic.isStaff);

    return (
        <PrivateRoute
            pathDisplay={pathDisplay}
            {...rest}
        >
            {isStaff ? children: <div>Admins only</div>}
        </PrivateRoute>
    )
};

export default AdminRoute;
// icons
import { ACCOUNT_MAIN_PAGE, MESSENGER_PAGE, SECURITY_PAGE } from "../../../globalConstants";
// Icons
import { ReactComponent as PressToLockIcon } from './lock-line.svg';
import { ReactComponent as PersonLine } from 'bootstrap-icons/icons/person-lines-fill.svg';
import { SendIcon } from "src/icons/SendIcon";
import { UnreadMessagesBadge } from "src/components/messenger/UnreadMessagesBadge";


export const SIDE_BAR_NAVS = [
    {title: "Account", url: ACCOUNT_MAIN_PAGE, icon: PersonLine, extra: () => null},
    {title: "Security", url: SECURITY_PAGE, icon: PressToLockIcon, extra: () => null},
    {title: "Messenger", url: MESSENGER_PAGE, icon: SendIcon, extra: UnreadMessagesBadge},
]
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line no-unused-vars
import { Tooltip, Toast, Popover } from 'bootstrap';

import './index.scss';
import axios from 'axios';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

// import * as serviceWorker from './serviceWorker';

// let testing = true;
if (process.env.NODE_ENV === 'production') {
  // disable react-dev-tools for this project
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
      for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
          window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? ()=>{} : null;
      }
  }
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  LogRocket.init('mplhfl/gradsu-frontend');
  setupLogRocketReact(LogRocket);

  console.log('Production mode')
} else {
  console.log('Development mode')
  // testing = false;
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app-root')
);

// reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import { useEffect, useState, useRef } from 'react';
import { useGeoLocation } from './time_and_location';
import { 
    useElementScrollYPosition, useElementScrollXPosition, 
    useElementTop, useElementBottom,
    useElementIsInViewport,useElementIsAboveHalfWindow
}from './elements';
import { useDeepCompareEffect } from './useDeepCompareEffect';

import { useWindowScrollYPosition, useWindowSize } from './browser';

export { 
    useElementScrollYPosition, useElementScrollXPosition, 
    useElementTop, useElementBottom,
    useElementIsInViewport,useElementIsAboveHalfWindow
};
export { useGeoLocation, useDeepCompareEffect };
export { useWindowScrollYPosition, useWindowSize };

/**
 * useful when state cannot wait for next render
*/
export function useStateRef<Type>(
        initialState: Type
    ) {
    const [state, setState] = useState(initialState);
    const stateRef = useRef(initialState);
    stateRef.current = state;
    return [state, setState, stateRef]
}

export function useDependentState<Type>(dependency: Type) {
    const [state, setState] = useState(dependency);
  
    useEffect(() => {
        setState(dependency);
    }, [dependency]);
  
    return [
        state,
        setState,
    ];
}

export function usePrevious<Type>(value: Type) {
    const ref = useRef<Type>(value);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

const BOOTSTRAP_BREAKPOINT_MD = 768;
export function useIsSmallScreen() {
    const windowSize = useWindowSize();
    return windowSize.width < BOOTSTRAP_BREAKPOINT_MD
}
// View: React and router
import React, { Fragment, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
// Components
import SideBar from './components/layout/sidebar/SideBar';
import Header from './components/layout/header/Header';
import PrivateRoute from './components/routes/PrivateRoute';
import AdminRoute from './components/routes/AdminRoute';
import SupervisorRoute from './components/routes/SupervisorRoute';
import StudentRoute from './components/routes/StudentRoute';
import ErrorBoundary from './components/error_boundary/ErrorBoundary';

// Constants
import {
    REGISTRATION_PAGE,
    LOGIN_ON_CONFIRMATION_PAGE,
    LOGIN_PAGE,
    PROFILE_BY_ID_PAGE,
    PROJECT_BY_ID_PAGE,
    MESSENGER_PAGE,
    CHANGE_PASSWORD_PAGE,
    PASSWORD_RECOVER_PAGE,
    PASSWORD_RESET_PAGE,
    BLOG_PAGE, FINDING_THE_RIGHT_SUPERVISOR_PAGE,
    MATCHING_HOW_TO_PAGE,
    PRIVACY_PAGE,
    TERMS_OF_SERVICE_PAGE,
    MISSION_PAGE,
    FAQ_PAGE,
    CONTACT_PAGE,
    SUBSCRIPTION_PAGE,
    CHANGE_SUBSCRIPTIONS_PAGE,
    REFERENCE_PAGE,
    REPORT_REFERENCE_PAGE,
    STUDENT_REFERENCE_PAGE,
    MATCHING_TABLE_PAGE_PREFIX,
    MATCHING_TABLE_PAGE, 
    BROWSING_PAGE,
    PROJECTS_PAGE,
    SECURITY_PAGE,
    ACCOUNT_MAIN_PAGE,
    SUPPORT_US_PAGE,
    WHAT_IS_GRADUATE_SCHOOL_PAGE,
    PRICING_PAGE,
    INDIVIDUAL_PLAN_PAGE,
    ADMIN_PAGE,
    LANDING_PAGE,
    SUPERVISOR_PAGE,
    MANAGEMENT_PAGE,
    UPDATE_PAGE,
    HIGH_SCORES_PAGE,
    USER_SCORES_PAGE,
    ACTIVITY_SCORES_PAGE
    // LAB_PAGE,
    // PAYMENTS_SUBSCRIPTIONS_PAGE

} from './globalConstants';


// Pages - lazy loaded
const HighScoresPage = lazy(() => import('./pages/management/HighScoresPage'));
const UserScoresPage = lazy(() => import('./pages/management/UserScoresPage'));
const ActivityScoresPage = lazy(() => import('./pages/management/ActivityScoresPage'));

const LandingPage = lazy(() => import('./pages/landing_page/LandingPage'));
const BlogPage = lazy(() => import('./pages/blog/BlogPage'));
const BlogContainer = lazy(() => import('./components/blog/BlogContainer'));
const FindingTheRightSupervisor = lazy(() => import('./pages/blog/finding_the_right_supervisor/FindingTheRightSupervisor'));
const WhatIsGraduateSchool = lazy(() => import('./pages/blog/what_is_graduate_school/WhatIsGraduateSchool'));

const MatchingHowToPage = lazy(() => import('./pages/how_tos/MatchingHowToPage'));

const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const TermsOfServicePage = lazy(() => import('./pages/TermsOfServicePage'));
const MissionPage = lazy(() => import('./pages/mission_page/MissionPage'));
const FaqPage = lazy(() => import('./pages/faq_page/FaqPage'));

const RegistrationPage = lazy(() => import('./pages/registration/RegistrationPage'));
const LoginOnConfirmationPage = lazy(() => import('./pages/login_token/LoginOnConfirmationPage'));

const LoginManager = lazy(() => import('./pages/login/LoginManager'));

const StudentManager = lazy(() => import('./pages/preferences/StudentManager'));
const ProjectManager = lazy(() => import('./pages/preferences/ProjectManager'));

const ProjectsPage = lazy(() => import('./pages/ProjectsPage'));

const ReferencePage = lazy(() => import('./pages/reference_page/SubmitReferencePage'));
const ReportReferencePage = lazy(() => import('./pages/report_pages/ReportReferencePage'));
const SeeReferencePage = lazy(() => import('./pages/reference_page/SeeReferencePage'));

const MatchingTablePage = lazy(() => import('./pages/matching_table/MatchingTablePage'));
const BrowsingPage = lazy(() => import('./pages/BrowsingPage'));
const ManagementPage = lazy(() => import('./pages/management/ManagementPage'));
const UpdatePage = lazy(() => import('./pages/management/UpdatePage'));
const SupervisorPage = lazy(() => import('./pages/supervisor/SupervisorPage'));

const PasswordResetRequest = lazy(() => import('./pages/password_reset_request/PasswordResetRequest'));
const PasswordReset = lazy(() => import('./pages/password_reset/PasswordReset'));
const PasswordChange = lazy(() => import('./pages/password_change/PasswordChange'));

const SecurityPage = lazy(() => import('./pages/SecurityPage'));
const GeneralPersonalPage = lazy(() => import('./pages/GeneralPersonalPage'));

const ContactForm = lazy(() => import('./pages/contact_us/ContactForm'));

const SubscriptionForm = lazy(() => import('./pages/subscription_page/SubscriptionForm'));
const ChangeSubscriptionsPage = lazy(() => import('./pages/subscriptions_change/ChangeSubscriptionsPage'));

const PricingPage = lazy(() => import('./pages/pricing/PricingPage'));
const IndividualPlanPage = lazy(() => import('./pages/pricing/IndividualPlanPage'));

const MessengerPage = lazy(() => import('./pages/MessengerPage'));

const Footer = lazy(() => import('./components/layout/footer/Footer'));

const SupportUsPage = lazy(() => import('./pages/support_us/SupportUsPage'));

const AdminPage = lazy(() => import('./pages/admin/AdminPage'));


const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));

const UnstyledBoundary = ({
        children
    }: {children: JSX.Element}) => {

    return (
        <Fragment>
            <Header />
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </Fragment>
    )
}


const SideBarredBoundary = ({
        children
    }: {children: JSX.Element}) => {

    return (
        <Fragment>
            <Header />
            <main className="d-flex flex-wrap">
                <SideBar />
                <ErrorBoundary>
                    <div className="sidebar-sibling" style={{"minHeight": "70vh"}}>
                        {children}
                    </div>
                </ErrorBoundary>
            </main>
            <Footer />
        </Fragment>
    )
}

/** Main routes
** SID: ?
*/
export const MainRoutes = () => {
    return (
        <Switch> 
            {/* Because landing is early child of switch, path needs to be 
            exact to avoid matching for any route that starts with "/" */}
            {/* Header is not here because it has to be inside LandingPage */}
            <Route exact path={LANDING_PAGE}>
                <ErrorBoundary>
                    <LandingPage/>
                </ErrorBoundary>
                <Footer />
            </Route>

            {/* Header is not here because it has to be inside MissionPage */}
            <Route exact path={MISSION_PAGE}>
                <ErrorBoundary>
                    <MissionPage />
                </ErrorBoundary>
            </Route>

            
            <AdminRoute path={ADMIN_PAGE}>
                <SideBarredBoundary>
                    <AdminPage />
                </SideBarredBoundary>
            </AdminRoute>

            <Route path={FINDING_THE_RIGHT_SUPERVISOR_PAGE + "*"}>
                <SideBarredBoundary>
                    <BlogContainer url={FINDING_THE_RIGHT_SUPERVISOR_PAGE}>
                        <FindingTheRightSupervisor />
                    </BlogContainer>
                </SideBarredBoundary>
            </Route>

            
            <Route path={WHAT_IS_GRADUATE_SCHOOL_PAGE + "*"}>
                <SideBarredBoundary>
                    <BlogContainer url={WHAT_IS_GRADUATE_SCHOOL_PAGE}>
                        <WhatIsGraduateSchool />
                    </BlogContainer>
                </SideBarredBoundary>
            </Route>

            <Route exact path={FAQ_PAGE}>
                <SideBarredBoundary>
                    <FaqPage />
                </SideBarredBoundary>
            </Route>
            

            {/* Preferences pages */}
            {/* ProjectManager and StudentManager don't have sideBar here because it has to be internal */}
            <PrivateRoute pathDisplay="that project" exact path={PROJECT_BY_ID_PAGE}>
                <UnstyledBoundary>
                    <ProjectManager/>
                </UnstyledBoundary>
            </PrivateRoute>

            <PrivateRoute pathDisplay="that preference page" exact path={PROFILE_BY_ID_PAGE}>
                <UnstyledBoundary>
                    <StudentManager/>
                </UnstyledBoundary>
            </PrivateRoute>

            {/* Supervisor's page */}
            <PrivateRoute pathDisplay="that supervisor's page" exact path={SUPERVISOR_PAGE}>
                <SideBarredBoundary>
                    <SupervisorPage />
                </SideBarredBoundary>
            </PrivateRoute>


            {/* Private pages with sideBar */}
            <PrivateRoute pathDisplay="your security page" exact path={SECURITY_PAGE}>
                <SideBarredBoundary>
                    <SecurityPage />
                </SideBarredBoundary>
            </PrivateRoute>

            <PrivateRoute pathDisplay="your account page" exact path={ACCOUNT_MAIN_PAGE}>
                <SideBarredBoundary>
                    <GeneralPersonalPage />
                </SideBarredBoundary>
            </PrivateRoute>

            {/* Header is not here because it has to be inside MessengerPage */}
            <PrivateRoute pathDisplay="the messenger page" exact path={MESSENGER_PAGE}>
                <ErrorBoundary>
                    <MessengerPage/>
                </ErrorBoundary>
            </PrivateRoute>

            <StudentRoute pathDisplay="the update page" exact path={UPDATE_PAGE}>
                <SideBarredBoundary>
                    <UpdatePage />
                </SideBarredBoundary>
            </StudentRoute>

            {/*
            * If switch immediate children don't have a path, they are 
            considered a route with an empty path, so div needs to be last.
            * This div includes a header and footer then puts the content in a styled div
            */}
            <div>
                <Header />

                <main className="col-12 px-3 px-md-5 pb-5" style={{"minHeight": "70vh"}}>
                    <Switch> 
                        <Route exact path={BLOG_PAGE}>
                            <ErrorBoundary>
                                <BlogPage />
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={PRIVACY_PAGE}> 
                            <ErrorBoundary>
                                <PrivacyPolicyPage />
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={TERMS_OF_SERVICE_PAGE}>
                            <ErrorBoundary>
                                <TermsOfServicePage />
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={CONTACT_PAGE}> 
                            <ErrorBoundary>
                                <ContactForm />
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={SUBSCRIPTION_PAGE}> 
                            <ErrorBoundary>
                                <SubscriptionForm />
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={CHANGE_SUBSCRIPTIONS_PAGE}>
                            <ErrorBoundary>
                                <ChangeSubscriptionsPage />
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={PRICING_PAGE}>
                            <ErrorBoundary>
                                <PricingPage />
                            </ ErrorBoundary>
                        </Route>

                        <PrivateRoute pathDisplay="the individual plan page" exact path={INDIVIDUAL_PLAN_PAGE}>
                            <ErrorBoundary>
                                <IndividualPlanPage />
                            </ ErrorBoundary>
                        </PrivateRoute>

                        {/* References */}

                        <Route exact path={REFERENCE_PAGE}>
                            <ErrorBoundary>
                                <ReferencePage />
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={REPORT_REFERENCE_PAGE}>
                            <ErrorBoundary>
                                <ReportReferencePage />
                            </ErrorBoundary>
                        </Route>                

                        <Route exact path={STUDENT_REFERENCE_PAGE}>
                            <ErrorBoundary>
                                <SeeReferencePage />
                            </ErrorBoundary>
                        </Route>

                        {/* Registration */}
                        <Route exact path={REGISTRATION_PAGE}> 
                            <ErrorBoundary>
                                <RegistrationPage />
                            </ErrorBoundary>
                        </Route>                       

                        <Route exact path={LOGIN_ON_CONFIRMATION_PAGE}>
                            <ErrorBoundary>
                                <LoginOnConfirmationPage />
                            </ErrorBoundary>
                        </Route>

                        {/* Login Urls */}
                        <Route exact path={LOGIN_PAGE}>
                            <ErrorBoundary>
                                <LoginManager/>
                            </ErrorBoundary>
                        </Route>

                        {/* How-to */}
                        <Route exact path={MATCHING_HOW_TO_PAGE}> 
                            <ErrorBoundary>
                                <MatchingHowToPage />
                            </ErrorBoundary>
                        </Route>

                        {/* Matching table */}
                        {/* For when no page number is entered */}
                        <PrivateRoute pathDisplay="the matching table" exact path={MATCHING_TABLE_PAGE_PREFIX}>
                            <ErrorBoundary>
                                <MatchingTablePage />
                            </ErrorBoundary>
                        </PrivateRoute>

                        <PrivateRoute pathDisplay="the matching table" exact path={MATCHING_TABLE_PAGE}>
                            <ErrorBoundary>
                                <MatchingTablePage />
                            </ErrorBoundary>
                        </PrivateRoute>

                        {/* Browsing projects */}
                        <PrivateRoute pathDisplay="your projects page" exact path={PROJECTS_PAGE}>
                            <ErrorBoundary>
                                <ProjectsPage />
                            </ErrorBoundary>
                        </PrivateRoute>

                        <PrivateRoute pathDisplay="the project browsing page" exact path={BROWSING_PAGE}>
                            <ErrorBoundary>
                                <BrowsingPage />
                            </ErrorBoundary>
                        </PrivateRoute>

                        <SupervisorRoute pathDisplay="the management page" exact path={MANAGEMENT_PAGE}>
                            <ErrorBoundary>
                                <ManagementPage />
                            </ErrorBoundary>
                        </SupervisorRoute>

                        {/* Password */}
                        <Route exact path={PASSWORD_RESET_PAGE}> 
                            <ErrorBoundary>
                                <PasswordReset />
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={PASSWORD_RECOVER_PAGE}> 
                            <ErrorBoundary>
                                <PasswordResetRequest/>
                            </ErrorBoundary>
                        </Route>

                        <PrivateRoute pathDisplay="the password change page" exact path={CHANGE_PASSWORD_PAGE}> 
                            <ErrorBoundary>    
                                <PasswordChange />
                            </ErrorBoundary>
                        </PrivateRoute>

                        <Route exact path={SUPPORT_US_PAGE}>
                            <ErrorBoundary>
                                <SupportUsPage />
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={HIGH_SCORES_PAGE}>
                            <ErrorBoundary>
                                <HighScoresPage/>
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={USER_SCORES_PAGE}>
                            <ErrorBoundary>
                                <UserScoresPage/>
                            </ErrorBoundary>
                        </Route>

                        <Route exact path={ACTIVITY_SCORES_PAGE}>
                            <ErrorBoundary>
                                <ActivityScoresPage/>
                            </ErrorBoundary>
                        </Route>

                        <Route path="*">
                            <NotFoundPage />
                        </Route>
                    </Switch>
                </main>
                <Footer />
            </div>

        </Switch>
    )
}

export default MainRoutes
import { CREATE_ALERT } from '../actions/types';
import { AnyAction } from 'redux';


export interface AlertsDropdownState {
    msg: {[index: string] : string | string[]},
    status: number | null
}

const initialState: AlertsDropdownState = {
    msg: {},
    status: null
}

export default function alertsDropdown(state = initialState, action: AnyAction) {
    switch(action.type) {
        case CREATE_ALERT:
            return {
                ...state,
                msg: action.payload.msg,
                status: action.payload.status
            }
        default:
            return state;
    }
}
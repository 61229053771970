import { 
    LOGOUT_SUCCESS,
    LOGOUT_IS_OLD,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADING,
    USER_LOADED,
    SET_TOKEN,
    AUTH_ERROR,
    INITIATE_LOGIN_SUCCESS,
    INITIATE_LOGIN_FAIL,
} from '../actions/types';
import { AnyAction } from 'redux';
import { TOKEN_KEY } from '../globalConstants';
import { UserTypeType } from '../types';

export interface AuthenticationBasicState {
    /** Backend user key */
    token: string | null,
    isAuthenticated: boolean | null, 
    userID: string | null,
    email: string | null,
    userType: UserTypeType,
    isStaff: boolean | null,
    isSubscribed: boolean,
    /** whether user is loaded - user unloads on refreshing page */
    isLoading: boolean,
    recentlyLoggedOut: boolean,
}

const initialState: AuthenticationBasicState = {
    token: localStorage.getItem(TOKEN_KEY),
    isAuthenticated: null, 
    userID: null,
    email: null,
    userType: null,
    isStaff: null,
    isSubscribed: false,
    isLoading: true,
    recentlyLoggedOut: false,
}

export default function authenticationBasic(state = initialState, action: AnyAction) {
    switch (action.type) {
        case INITIATE_LOGIN_SUCCESS:
            return {
                ...state,
                email: action.payload,
            }
        case INITIATE_LOGIN_FAIL:
            return {
                ...state,
                email: null,
            }
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                userID: action.payload.user.pk,
                email: action.payload.user.email,
                userType: action.payload.user_type,
                isStaff: action.payload.is_staff,
                isSubscribed: action.payload.is_subscribed,
                isLoading: false,
            }
        case LOGIN_SUCCESS:
            localStorage.setItem(TOKEN_KEY, action.payload.token);
            return {
                ...state,
                token: action.payload.token,
                isAuthenticated: true,
                userID: action.payload.user.pk,
                email: action.payload.user.email,
                userType: action.payload.user_type,
                isStaff: action.payload.is_staff,
                isSubscribed: action.payload.is_subscribed,
                isLoading: false,
            }
        case SET_TOKEN:
            localStorage.setItem(TOKEN_KEY, action.payload);
            debugger;
            return {
                ...state,
                token: action.payload,
            }
        case AUTH_ERROR:
            localStorage.removeItem(TOKEN_KEY);
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                userID: null,
                email: null,
                userType: null,
                isStaff: null,
                isSubscribed: false,
                isLoading: null,
            };
        case LOGOUT_SUCCESS:
            localStorage.removeItem(TOKEN_KEY);
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                userID: null,
                email: null,
                userType: null,
                isStaff: null,
                isSubscribed: false,
                isLoading: null,
                recentlyLoggedOut: true,
            };
        case LOGOUT_IS_OLD:
            return {
                ...state,
                recentlyLoggedOut: false,
            };
        case LOGIN_FAIL:
            localStorage.removeItem(TOKEN_KEY);
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                userID: null,
                userType: null,
                isStaff: null,
                isSubscribed: false,
                isLoading: null,
            };
        default:
            return state;
    }
}
import { AnyAction } from 'redux';
import { GET_CV_ACCESS_LISTS_SUCCESSFUL } from '../actions/types';


export interface MatchingTableState {
    /** If supervisor, list of student ids. If student, list of supervisor ids */
    pendingCvList: number[]
    /** If supervisor, list of student ids. If student, list of supervisor ids */
    allowedCvList: number[]
}

const initialState: MatchingTableState = {
    pendingCvList: [],
    allowedCvList: []
}

export default function matchingTable(state = initialState, action: AnyAction) {
    switch(action.type) {
        // for supervisors
        case GET_CV_ACCESS_LISTS_SUCCESSFUL:
            return {
                ...state,
                pendingCvList: action.payload.students_for_which_cv_access_is_pending,
                allowedCvList: action.payload.students_that_allow_cv_access,
            }
        default:
            return state;
    }
}
// View: React
import { useEffect } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { inactivateTab, activateTab } from '../../actions/general';



export const TabVisibilityMonitor = () => {

    const dispatch = useDispatch();

    // Set the name of the hidden property and the change event for visibility
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }

    useEffect(() => {
        function handleVisibilityChange() {
            if (document[hidden]) {
                dispatch(inactivateTab())
            } else {
                dispatch(activateTab())
            }
        }
    
        // Warn if the browser doesn't support addEventListener or the Page Visibility API
        if (hidden === undefined) {
            // console.log("Browser does not support document.hidden.");
            // pass
        } else {
            // Handle page visibility change
            document.addEventListener(visibilityChange, handleVisibilityChange, false);
        }

        return () => document.removeEventListener(visibilityChange, handleVisibilityChange, false);
    })

    return null
}

export default TabVisibilityMonitor
// View: React
import React, { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
// Constants
import { CONTACT_PAGE } from '../../globalConstants';

// Images
const LabAlien = lazy(() => import('./LabAlien'));

/** UI for he error boundary
 ** SID: N/A (general)
 * @author Nassir Al-Khishman 
*/
export const ErrorComponent = () => {
    return (
        <Suspense fallback={<div></div>}>
            <div className="p-5 m-auto" style={{ "maxWidth": "500px" }}>
                <h1 className="mb-3">Uh oh</h1>

                <div className="mb-4" style={{"maxWidth" : "500px"}}>
                    {`Looks like something went wrong. Please try again later. If convenient, `}
                    
                    <Link to={CONTACT_PAGE}>
                        send us a message
                    </Link>
                    .
                </div>

                <LabAlien
                    height="100%"
                    width="100%"
                />
            </div>
        </Suspense>
    )
}

export default ErrorComponent

// View: React and Router-DOM
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
// Actions
import { reverseRemoveHyphens } from '../../utils';
// Style
import './TableOfContents.scss';
// Icon
import { ReactComponent as ArrowDown } from 'bootstrap-icons/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'bootstrap-icons/icons/arrow-up.svg';
// import { ReactComponent as ArrowLeft } from 'bootstrap-icons/icons/arrow-left-short.svg';
import { BaseButton } from '../buttons/BaseButton';
// Constants
import { ICON_SIZE_MEDIUM, PROFILE_BY_ID_PAGE_SUFFIX, PROJECT_BY_ID_PAGE_PREFIX } from '../../globalConstants';
import { useIsSmallScreen } from '../../hooks';


const FORMLIKE_PAGES = [
    PROFILE_BY_ID_PAGE_SUFFIX,
    PROJECT_BY_ID_PAGE_PREFIX,
]


type ExtendedElement = Element & {isLastHeadingAboveHalf: boolean}

interface BaseContentSectionType {
    headingName: string;
    isLastHeadingAboveHalf: boolean;
    isEmpty: boolean;
    erroneous: boolean;
}
interface H3ContentSectionType extends BaseContentSectionType {}
interface H2ContentSectionType extends BaseContentSectionType {
    subContent: H3ContentSectionType[];
}


type TableOfContentsAutomaticPropsType = {    
    /** Modify class of the TOC that is visible when screen > md.*/
    bigTocClassPartial?: string,
    /** determine whether or not to show toc. 
     * using d-none because it needs to be rendered for progress bar to work.
    */
    hidden?: boolean,
}

/** A table of contents automatically created using h2s and h3s
 ** Automatically updates when anything in the body is mutated
 ** SID: 0,025
 * @author Nassir Al-Khishman 
*/
const TableOfContentsAutomatic = React.memo(({
        bigTocClassPartial = "px-3 pb-3",
        hidden = false
    }: TableOfContentsAutomaticPropsType) => {

    const [smallTocCurrentHeading, setSmallTocCurrentHeading] = useState('Contents');
    const [showSmallToc, setShowSmallToc] = useState(false);
    const [smallTocButtonArrowDown, setSmallTocButtonArrowDown] = useState(true);
    const [contentsArray, setContentsArray] = useState<H2ContentSectionType[]>([]);

    const isSmallScreen = useIsSmallScreen();

    const location = useLocation();

    useEffect(() => {

        const updateTOC = () => {
            let headings = document.querySelectorAll("h2, h3, p[role='alert']") as NodeListOf<ExtendedElement>;
    
            // find out which heading should be currently active (latest one that is is-above-half)
            let indexOfLastHeadingAboveHalf = 0;
            for (const [index, heading] of headings.entries()) {
                if (heading.className.includes('is-above-half')) {
                    indexOfLastHeadingAboveHalf = index;
                }
            }
    
            // get object of arrays that each start with an h2 element and have a bunch of h3 elements with their errors
            // eg: returns {1: [H2, H3, H3, Error, H3], 2: [H2, H3 H3]}
            // also modify elements to add property isLastHeadingAboveHalf
            let contents: {[index: number]: Array<ExtendedElement>} = {};
            let currentSectionNumber = 0;
            for (const [index, element] of headings.entries()) {
                // create new array
                if (element.nodeName === 'H2') {
                    currentSectionNumber = currentSectionNumber + 1;
                    contents[currentSectionNumber] = [];
                }
                // modification
                if (index === indexOfLastHeadingAboveHalf) {
                    element['isLastHeadingAboveHalf'] = true;
                } else {
                    element['isLastHeadingAboveHalf'] = false;
                }
                // put element in array
                if (contents[currentSectionNumber] != null) {
                    contents[currentSectionNumber].push(element);
                }
            }
            const numberOfSections = currentSectionNumber;
    
    
            // convert each array into an object with the right keys. Then, add it into the contents array
            let contentsArray: H2ContentSectionType[] = [];
            let currentSection;
            for (let sectionKey = 1; sectionKey < (numberOfSections + 1); sectionKey++) {
                currentSection = contents[sectionKey];
                const h2Heading = currentSection[0];
    
                // get h2 headings
                let subContent: H3ContentSectionType[] = [];
                for (const [index, sectionItem] of currentSection.entries()) {
                    if (sectionItem.nodeName === 'H3') {
                        let nextIndex = index + 1;
                        let nextItem =  currentSection[nextIndex];
                        let subsectionIsErroneous;
                        // index invalid (currentSection does not have it)
                        // if nothing after h3, then no error 
                        if (nextItem == null) {
                            subsectionIsErroneous = false;
                        } else {
                            subsectionIsErroneous = nextItem.className.includes('field-error');
                        }
    
                        const headingText = sectionItem.textContent? sectionItem.textContent : "-"
                        const subContentItem = {
                            headingName: headingText,
                            isLastHeadingAboveHalf: sectionItem.isLastHeadingAboveHalf,
                            erroneous: subsectionIsErroneous,
                            isEmpty: sectionItem.className.includes('is-empty')
                        }
                        subContent.push(subContentItem);
                    }
                }
                // get whether any h2 are empty
                let subContentHasEmptyItems;
                if (subContent.length < 1) {
                    subContentHasEmptyItems = true;
                } else {
                    subContentHasEmptyItems = subContent.some((el) => el.isEmpty === true);
                }
                // put subcontents together with heading
                const headingText = h2Heading.textContent? h2Heading.textContent : "-"
                const currentSectionAsObject = {
                    headingName: headingText,
                    isLastHeadingAboveHalf: h2Heading.isLastHeadingAboveHalf,
                    isEmpty: subContentHasEmptyItems,
                    erroneous: subContent.some(e => e.erroneous === true),
                    subContent: subContent,
                }
                contentsArray.push(currentSectionAsObject)
            }
    
            setContentsArray(contentsArray)
        }    


        updateTOC()
        const body = document.querySelector("body");
        const config = { attributes: true, childList: true, subtree: true };
        const bodyObserver = new MutationObserver(updateTOC);
        if (body) {
            bodyObserver.observe(body, config);
        }
        return () => bodyObserver.disconnect();

    }, [])

    // Setters
    
    const activeSmallToc = () => {
        setShowSmallToc(true);
        setSmallTocButtonArrowDown(false);
    }

    const hideSmallToc = () => {
        setShowSmallToc(false);
        setSmallTocButtonArrowDown(true);
    }

    const handleClickSmallTocButton = () => {
        if (showSmallToc === false) {
            activeSmallToc()
        } else {
            hideSmallToc()
        }
    }

    const handleClickLinkSmallToc = (headingName: string) => {
        setSmallTocCurrentHeading(headingName)
        hideSmallToc()
    }
    
    const handleClickLink = (headingName: string) => {
        handleClickLinkSmallToc(headingName)
    }

    // Getters

    const getTocContent = (
            contentOrSubcontent: H3ContentSectionType | H2ContentSectionType,
            partialContentOrSubcontentClass: string
        ) => {
        
        const contentOrSubcontentClass = [partialContentOrSubcontentClass, contentOrSubcontent.isLastHeadingAboveHalf ? 'is-above-half' : null].join(' ').trim();
        
        let contentOrSubcontentLinkClass = `text-decoration-none ${partialContentOrSubcontentClass}-link`;
        
        for (const address of FORMLIKE_PAGES) {
            if (location.pathname.includes(address)) {
                contentOrSubcontentLinkClass = [
                    `text-decoration-none ${partialContentOrSubcontentClass}-link`,
                    contentOrSubcontent.erroneous ? 'link-danger' : null,
                    contentOrSubcontent.isEmpty ? null : 'link-is-not-empty',
                ].join(' ').trim();
            }
        }
        // adding a key was causing a bug - when closing a dialog for activites with similar name would duplicate
        return (
            <li className={contentOrSubcontentClass}>
                <HashLink
                    smooth
                    to={`${location.pathname}#${reverseRemoveHyphens(contentOrSubcontent.headingName)}`}
                    className={contentOrSubcontentLinkClass}
                    onClick={() => handleClickLink(contentOrSubcontent.headingName)}
                >
                    {contentOrSubcontent.headingName}
                </HashLink>
            </li>
        )
    }

    const getToc = () => {
        return (
            contentsArray.map((content) => {

                const mainContent = getTocContent(content, 'toc-content')

                const subContents = content.subContent.map((subcontent) => {
                    return getTocContent(subcontent, 'toc-subcontent')
                })
                // adding a key was causing a bug - when closing a dialog for activites with similar name would duplicate
                return (
                    <li> 
                        <ul>
                            {mainContent}
                            {subContents}
                        </ul>
                    </li>
                )
            })
        )
    }

    if (isSmallScreen) {
        return (
            <nav id="table-of-contents" className={"bg-majority shadow-sm" + (hidden ? " d-none" :"")}>
                <BaseButton
                    handleAction={() => handleClickSmallTocButton()}
                    className="d-flex container-fluid btn text-left"
                    id="btnToc" 
                    ariaLabel={showSmallToc ? "hide menu" : "show menu"}
                    ariaHasPopUp="listbox"
                    ariaExpanded={showSmallToc}
                    icon={smallTocButtonArrowDown ?
                        <ArrowDown className="ms-auto" height={ICON_SIZE_MEDIUM} width={ICON_SIZE_MEDIUM} /> 
                    :
                        <ArrowUp className="ms-auto" height={ICON_SIZE_MEDIUM} width={ICON_SIZE_MEDIUM} />
                    }
                >
                    {smallTocCurrentHeading}
                </BaseButton>
                {showSmallToc ? 
                    <ul className="small-toc shadow-low-offset">
                        {getToc()}
                    </ul>
                :
                    null
                }
            </nav>
        )
    } else {
        return (
            <nav id="table-of-contents" className={"scrollbar-nice me-3 " + bigTocClassPartial + (hidden ? " d-none" :"")}>
                <ul>
                    {getToc()}
                </ul>
            </nav>
        )
    }


})

export default TableOfContentsAutomatic
// View: React and Router-DOM
import React from 'react'
// State: React-Redux
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import PrivateRoute, { PrivateRoutePropsType } from './PrivateRoute';


/** Encapsulate routes that require supervisors
 * @author Nassir Al-Khishman 
*/
const SupervisorRoute = ({
        pathDisplay = "that page",
        children,
        ...rest
    }: PrivateRoutePropsType) => {//component is within element tag

    const userType = useSelector((state: ReduxState) => state.authenticationBasic.userType);

    return (
        <PrivateRoute
            pathDisplay={pathDisplay}
            {...rest}
        >
            {userType === 'supervisor' ? 
                children
            : 
                <div className='pt-3'>
                    This page is for supervisors only
                </div>
            }
        </PrivateRoute>
    )
};

export default SupervisorRoute;
import { AnyAction } from 'redux';
import {
    ASYNC_FAIL,
    ASYNC_RESET
} from '../actions/types';


export interface ErrorsState {
    asyncFailed: boolean
}

const initialState: ErrorsState = {
    asyncFailed: false,
}

export default function errors(state = initialState, action: AnyAction) {
    switch (action.type) {
        case ASYNC_FAIL:
            return {
                asyncFailed: true,
            }
        case ASYNC_RESET:
            return {
                asyncFailed: false,
            }
        default:
            return state;
    }
}
import { preprocessBackendErrors } from '../utils/preprocessBackendErrors';
import { tokenConfig } from './authentication_basic';
import {
    ACTIVATE_TAB,
    INACTIVATE_TAB,
    SET_SUB
} from './types';
import { CancelableActionCreator, DispatchType, HandleBackendErrorsType, HandleBackendSuccessType } from '../types';
import axios from 'axios';
import { ReduxState } from '../reducers';

/** Get data
 ** SID: ?
*/
export const fetchAuthenticated = (
        url: string,
        handleBackendErrors: HandleBackendErrorsType,
        handleBackendSuccess: HandleBackendSuccessType
    ): CancelableActionCreator => (dispatch, getState) => {
    const source = axios.CancelToken.source();

    axios.get(url, Object.assign({}, tokenConfig(getState), { cancelToken: source.token }))
        .then(res => {
            handleBackendSuccess(res.data)
        })
        .catch(err => {
            if (axios.isCancel(err)) {
                // pass
            } else {
                dispatch(preprocessBackendErrors(handleBackendErrors, err))
            }
        })
    
    return source
}


/** Send a messge indicated that tab is active
 ** SID: ?
*/
export const activateTab = () => {
    return {
        type: ACTIVATE_TAB,
    }
}


/** Send a messge indicated that tab is inactive
 ** SID: ?
*/
export const inactivateTab = () => {
    return {
        type: INACTIVATE_TAB,
    }
}


type VisibilitySensitiveSetIntervalType = {
    (
      dispatch: DispatchType,
      getState: () => ReduxState
    ): number;
}
/** Although this uses an action creator, that action creator does not create any actions. 
 * Instead, the action creator's purpose is to get getState() function, which allows getting the state in a non-stale way.
 ** SID: ?
*/
export const visibilitySensitiveSetInterval = (
        callback: () => any,
        delay: number,
        /** use a signature to make this a singleton */
        signature?: string
    ): VisibilitySensitiveSetIntervalType => (dispatch, getState) => {


    // singleton: clear old subs if there are any
    if (signature && signature.length > 0) {
        const activeSubs = getState().tabVisibility.activeSubs;
        if (signature in activeSubs) {
            const currentInterval = activeSubs[signature]
            clearInterval(currentInterval);
        }
    }

    const intervalId = window.setInterval(() => {
        if (getState().tabVisibility.isActive) {
            callback()
        }
    }, delay)

    // singleton: keep track of subs
    if (signature && signature.length) {
        dispatch({
            type: SET_SUB,
            payload: {
                name: signature,
                id: intervalId
            }
        })
    }

    return intervalId
}
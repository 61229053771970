// View: React and Router-DOM
import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
// State: React-Redux
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../reducers";
// Components
import { BaseButton } from "../../buttons/BaseButton";
import { DropDownArrow } from "../../common/DropDownArrow";
import ThemeSwitch from "./ThemeSwitch";
// Style
import "./Header.scss";
// Icons
import { ReactComponent as GradsunIcon } from "./gradsunFullText.svg";
import { ReactComponent as MyPreferencesIcon } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as MatchingTableIcon } from "bootstrap-icons/icons/table.svg";
import { ReactComponent as BrowseIcon } from "bootstrap-icons/icons/grid.svg";
import { ReactComponent as CollapseIcon } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as HowToIcon } from "bootstrap-icons/icons/question-square.svg";
// Constants
import {
  REGISTRATION_PAGE,
  LOGIN_PAGE,
  MATCHING_HOW_TO_PAGE,
  PROFILE_BY_ID_PAGE_PREFIX,
  MATCHING_TABLE_PAGE_PREFIX,
  BROWSING_PAGE,
  PROJECTS_PAGE,
  ICON_SIZE_SMALL,
  ACCOUNT_MAIN_PAGE,
  PRICING_PAGE,
  MANAGEMENT_PAGE,
  UPDATE_PAGE,
  LANDING_PAGE,
} from "../../../globalConstants";
import Alert from "../../alerts/Alert";
import { removeMainAlert } from "src/actions/main_alert";
import { UnreadMessagesBadge } from "src/components/messenger/UnreadMessagesBadge";

const MainAlert = () => {
  const mainAlerts = useSelector((state: ReduxState) => state.mainAlert.msgs);
  const dispatch = useDispatch();

  return (
    <Fragment>
      {mainAlerts.map((alert) => {
        return (
          <Alert
            key={alert.id}
            type="info"
            isCentered={true}
            hideCallback={() => dispatch(removeMainAlert(alert.id))}
          >
            {alert.msg}
          </Alert>
        );
      })}
    </Fragment>
  );
};

type MatchingDropDownProps = {
  navlinkClass: string;
  setShowMatchingOptions: (value: React.SetStateAction<boolean>) => void;
  children: JSX.Element | JSX.Element[];
};

const MatchingDropdown = ({
  navlinkClass,
  setShowMatchingOptions,
  children,
}: MatchingDropDownProps) => {
  return (
    <div
      className="row gy-2 p-3"
      onClick={() => setShowMatchingOptions(false)}
      role="presentation"
    >
      <div className="col-12">
        <NavLink
          to={MATCHING_HOW_TO_PAGE}
          className={[navlinkClass, "d-flex align-items-center"]
            .join(" ")
            .trim()}
          activeClassName="active"
        >
          <span className="me-5">How-to</span>
          <div className="ms-auto d-flex align-items-center">
            <HowToIcon height={ICON_SIZE_SMALL} width={ICON_SIZE_SMALL} />
          </div>
        </NavLink>
      </div>
      {children}
      <div className="col-12">
        <NavLink
          to={MATCHING_TABLE_PAGE_PREFIX}
          className={[navlinkClass, "d-flex align-items-center"]
            .join(" ")
            .trim()}
          activeClassName="active"
        >
          <span className="me-5">Table</span>
          <div className="ms-auto d-flex align-items-center">
            <MatchingTableIcon
              height={ICON_SIZE_SMALL}
              width={ICON_SIZE_SMALL}
            />
          </div>
        </NavLink>
      </div>
    </div>
  );
};

type SupervisorMatchingDropdownProps = {
  navlinkClass: string;
  setShowMatchingOptions: (value: React.SetStateAction<boolean>) => void;
};

const SupervisorMatchingDropdown = ({
  navlinkClass,
  setShowMatchingOptions,
}: SupervisorMatchingDropdownProps) => {
  return (
    <MatchingDropdown
      navlinkClass={navlinkClass}
      setShowMatchingOptions={setShowMatchingOptions}
    >
      <div className="col-12">
        <NavLink
          to={PROJECTS_PAGE}
          className={[navlinkClass, "d-flex align-items-center"]
            .join(" ")
            .trim()}
          activeClassName="active"
        >
          <span className="me-5">My projects</span>
          <div className="ms-auto d-flex align-items-center">
            <BrowseIcon height={ICON_SIZE_SMALL} width={ICON_SIZE_SMALL} />
          </div>
        </NavLink>
      </div>
    </MatchingDropdown>
  );
};

type StudentMatchingDropdownProps = {
  navlinkClass: string;
  setShowMatchingOptions: (value: React.SetStateAction<boolean>) => void;
  userID: string | null;
};

const StudentMatchingDropdown = ({
  navlinkClass,
  userID,
  setShowMatchingOptions,
}: StudentMatchingDropdownProps) => {
  return (
    <MatchingDropdown
      navlinkClass={navlinkClass}
      setShowMatchingOptions={setShowMatchingOptions}
    >
      <div className="col-12">
        <NavLink
          to={PROFILE_BY_ID_PAGE_PREFIX.concat(`/${userID}`)}
          className={[navlinkClass, "d-flex align-items-center"]
            .join(" ")
            .trim()}
          activeClassName="active"
        >
          <span className="me-5">My preferences</span>
          <div className="ms-auto d-flex align-items-center">
            <MyPreferencesIcon
              height={ICON_SIZE_SMALL}
              width={ICON_SIZE_SMALL}
            />
          </div>
        </NavLink>
      </div>
      <div className="col-12">
        <NavLink
          to={BROWSING_PAGE}
          className={[navlinkClass, "d-flex align-items-center"]
            .join(" ")
            .trim()}
          activeClassName="active"
        >
          <span className="me-5">Browse</span>
          <div className="ms-auto d-flex align-items-center">
            <BrowseIcon height={ICON_SIZE_SMALL} width={ICON_SIZE_SMALL} />
          </div>
        </NavLink>
      </div>
    </MatchingDropdown>
  );
};

type HeaderProps = {
  /** Class of navbar (bg color) */
  isTransparent?: boolean;
  /** Color of outline and text. */
  isMissionPage?: boolean;
};

/** The header
 ** SID: 0,012 Navigation
 * @author Nassir Al-Khishman
 */
export const Header = ({
  isMissionPage = false,
  isTransparent = false,
}: HeaderProps) => {
  /** determine whether to show login/logout, etc.*/
  const isAuthenticated = useSelector(
    (state: ReduxState) => state.authenticationBasic.isAuthenticated
  );
  /** Whether or not to show pricing page */
  const isSubscribed = useSelector(
    (state: ReduxState) => state.authenticationBasic.isSubscribed
  );
  /** Required to make profile link.*/
  const userID = useSelector(
    (state: ReduxState) => state.authenticationBasic.userID
  );
  /** Required to make profile link.*/
  const userType = useSelector(
    (state: ReduxState) => state.authenticationBasic.userType
  );

  const [showMatchingOptions, setShowMatchingOptions] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  let navClass;
  if (isTransparent) {
    navClass = "col-12 navbar";
  } else {
    navClass = "col-12 navbar border-bottom";
  }

  let navlinkClass;
  let logoStyle;
  if (isMissionPage) {
    logoStyle = {
      color: "white",
      "text-shadow": "0px 1px 0.5rem rgb(0, 0, 0)",
    } as React.CSSProperties;
    navClass = navClass + " navbar-dark";
    navlinkClass = "navlink-item text-light me-1";
  } else {
    logoStyle = undefined;
    navClass = navClass + " navbar-light";
    navlinkClass = "navlink-item me-1";
  }

  let authDependentContent;
  if (isAuthenticated) {
    authDependentContent = (
      <Fragment>
        <NavLink
          to={ACCOUNT_MAIN_PAGE}
          className={[navlinkClass, "d-flex align-items-center"]
            .join(" ")
            .trim()}
          activeClassName="active"
        >
          Account
          <UnreadMessagesBadge />
        </NavLink>
      </Fragment>
    );
  } else {
    authDependentContent = (
      <Fragment>
        <NavLink
          to={LOGIN_PAGE}
          className={[
            navlinkClass,
            isMissionPage ? "text-shadow-none" : null,
            "d-flex align-items-center",
          ]
            .join(" ")
            .trim()}
          activeClassName="active"
        >
          Login
        </NavLink>
        <NavLink
          to={REGISTRATION_PAGE}
          className={[
            "btn btn-logo-color ms-2",
            isMissionPage ? "text-shadow-none" : null,
            "d-flex align-items-center rounded",
          ]
            .join(" ")
            .trim()}
          activeClassName="active"
        >
          Register
        </NavLink>
      </Fragment>
    );
  }

  const allContent = (
    <Fragment>
      {/* {isAuthenticated ?
                null
            :
                <NavLink to={BLOG_PAGE} className={[navlinkClass, ''].join(' ').trim()} activeClassName="active" style={logoStyle}>Blog</NavLink>
            } */}

      {!isSubscribed && (
        <NavLink
          to={PRICING_PAGE}
          className={[navlinkClass, "d-flex align-items-center"]
            .join(" ")
            .trim()}
          activeClassName="active"
          style={logoStyle}
        >
          Pricing
        </NavLink>
      )}

      <div
        className="position-relative"
        onMouseEnter={() => setShowMatchingOptions(true)}
        onMouseLeave={() => setShowMatchingOptions(false)}
      >
        <BaseButton
          handleAction={() => setShowMatchingOptions(!showMatchingOptions)} // necessary for small screen
          className={
            navlinkClass +
            " btn d-flex justify-content-center align-items-center" +
            (showMatchingOptions ? "" : " h-100")
          }
          id="matching-dropdown"
          icon={<DropDownArrow isExpanded={showMatchingOptions} />}
        >
          Matching
        </BaseButton>
        {showMatchingOptions ? (
          userType === "supervisor" ? (
            <Fragment>
              <div
                className="d-none d-lg-block glassy third-highest-z shadow-sm justify-content-center position-absolute top-95 end-0"
                aria-labelledby="matching-dropdown"
              >
                <SupervisorMatchingDropdown
                  navlinkClass={navlinkClass}
                  setShowMatchingOptions={setShowMatchingOptions}
                />
              </div>

              {/* small screen - position is different */}
              <div
                className="d-block d-lg-none glassy third-highest-z shadow-sm justify-content-center position-absolute top-95"
                aria-labelledby="matching-dropdown"
              >
                <SupervisorMatchingDropdown
                  navlinkClass={navlinkClass}
                  setShowMatchingOptions={setShowMatchingOptions}
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div
                className="d-none d-lg-block glassy third-highest-z shadow-sm justify-content-center position-absolute top-95 end-0"
                aria-labelledby="matching-dropdown"
              >
                <StudentMatchingDropdown
                  userID={userID}
                  navlinkClass={navlinkClass}
                  setShowMatchingOptions={setShowMatchingOptions}
                />
              </div>

              {/* small screen - position is different */}
              <div
                className="d-block d-lg-none glassy third-highest-z shadow-sm justify-content-center position-absolute top-95"
                aria-labelledby="matching-dropdown"
              >
                <StudentMatchingDropdown
                  userID={userID}
                  navlinkClass={navlinkClass}
                  setShowMatchingOptions={setShowMatchingOptions}
                />
              </div>
            </Fragment>
          )
        ) : null}
      </div>

      {userType === "supervisor" ? (
        <NavLink
          to={MANAGEMENT_PAGE}
          className={[navlinkClass, "d-flex align-items-center"]
            .join(" ")
            .trim()}
          activeClassName="active"
          style={logoStyle}
        >
          Progress
        </NavLink>
      ) : (
        <NavLink
          to={UPDATE_PAGE}
          className={[navlinkClass, "d-flex align-items-center"]
            .join(" ")
            .trim()}
          activeClassName="active"
          style={logoStyle}
        >
          Progress
        </NavLink>
      )}

      {authDependentContent}
    </Fragment>
  );

  return (
    <Fragment>
      <header className={navClass} aria-label="header" id="header">
        <div className="container">
          <NavLink
            to={LANDING_PAGE}
            className="me-1 me-md-5"
            style={{ fontSize: "1.5rem" }}
            aria-label="Gradsun logo"
          >
            <GradsunIcon height="1.7em" width="8em" />
          </NavLink>

          <div className="d-flex mt-3 mt-sm-0">
            {/* smaller screen */}
            <div className="d-flex d-lg-none text-nowrap me-2">
              <BaseButton
                handleAction={() => setIsExpanded(!isExpanded)}
                className="btn shadow-none border shadow-no-offset py-1 px-2"
                icon={<CollapseIcon width={"1.5rem"} height={"1.5rem"} />}
                ariaLabel="navigation expansion"
                ariaExpanded={isExpanded}
              />
            </div>
            {/* large screen */}
            <nav className="d-none d-lg-flex position-relative text-nowrap">
              {allContent}
            </nav>
            <div className="d-flex align-items-center ms-2">
              <ThemeSwitch />
            </div>
          </div>
        </div>
      </header>
      {/* dropdown for small screen */}
      {isExpanded ? (
        <nav
          className="d-block d-lg-none container position-relative"
          id="small-screen-nav"
        >
          {allContent}
        </nav>
      ) : null}
      <MainAlert />
    </Fragment>
  );
};

export default Header;

import { useSelector } from "react-redux"
import { ReduxState } from "src/reducers"


export const UnreadMessagesBadge = () => {

    /** Show the number of unread messages in messenger.*/
    const unreadMessagesCount = useSelector((state: ReduxState) => state.messenger.unreadMessagesCount);

    if (unreadMessagesCount < 1) {
        return null
    } 
    return (
        <div className="badge bg-danger ms-2">
            {unreadMessagesCount}
        </div>
    )
}


// React
import React from 'react';
// icons
import { ReactComponent as ExpandIcon } from 'bootstrap-icons/icons/chevron-left.svg';
// Constants
import {
    ICON_SIZE_SMALL,
} from '../../globalConstants';

type DropDownArrowPropsType = {
    isExpanded: boolean
}
export const DropDownArrow = ({ isExpanded }: DropDownArrowPropsType) => {
    return (
        <ExpandIcon
            height={ICON_SIZE_SMALL} width={ICON_SIZE_SMALL} 
            className={"toggle-rotate" + (isExpanded ? " rotated" : "")}
        />
    )
}
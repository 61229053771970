import { toggleIfEnter } from "../../utils"


export interface ToggleSwitchPropsType {
    name: string,
    isToggled: boolean,
    toggle: () => void,
    toggledIcon?: any, untoggledIcon?: any, 
    /** The distance from the horizontal wall, in percentage */
    iconX?: number,
    visibleLabel?: string, inputId?: string
}
export const ToggleSwitch = ({
        name,
        isToggled,
        toggle,
        toggledIcon, untoggledIcon, iconX,
        visibleLabel = "", inputId
    }: ToggleSwitchPropsType) => {
    
    return (
        <div className="switch">
            <div className="switch-input-container">
                <input
                    id={inputId}
                    className="switch-input"
                    type="checkbox"
                    role="switch"
                    title={`Toggle ${name}`}
                    checked={isToggled ? true : false}
                    aria-checked={isToggled ? true : false}
                    aria-label={`${name}`}
                    onKeyDown={(e) => toggleIfEnter(e, toggle)}
                    onChange={() => toggle()}
                />
                {isToggled ?
                    toggledIcon?
                        <span
                            style={{"left": `${iconX}%`}}
                            className="switch-icon"
                            role="img"
                            aria-label={`${name} is on`}
                        >
                            {toggledIcon}
                        </span>
                    :
                        null
                :
                    untoggledIcon?
                        <span
                            style={{"right": `${iconX}%`}}
                            className="switch-icon"
                            role="img"
                            aria-label={`${name} is off`}
                        >
                            {untoggledIcon}
                        </span>
                    :
                        null
                }
            </div>
            {visibleLabel?
                <label
                    htmlFor={inputId}
                    className="switch-label"
                >
                    {visibleLabel}
                </label>
            :
                null
            }

        </div>
    )
}
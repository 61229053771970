// default datetime format = 'dayName month day, year HH:MM:SS' eg: Tue Nov 23, 2021 23:59:59
// alternative date format = 'yyyy-MM-dd';
export type DateType = {
    decade: number,
    year: number | null,
    month: number | null,
    day: number | null,
}


export const MILLIS_PER_DAY = 1000 * 60 * 60 * 24;

export const parseDateInDefFormat = (date: string) => {
    const arr = date.split(" ");
    const dayName = arr[0];
    const month = arr[1];
    const dayNumber = arr[2];
    const year = arr[3];
    return {
        dayName,
        month,
        dayNumber,
        year
    }
}

export const parseDuration = (duration: string) => {
    const [dh, m, s] = duration.split(":");
    let d = "0";
    let h = "0";
    if (dh.length > 2) {
        [d, h] = dh.split(' ');
    } else {
        h = dh;
    }
    return { d, h, m, s } 
}

export const parseDurationToSeconds = (duration: string) => {
    const { d, h, m, s } = parseDuration(duration);
    const seconds = (Number(d) * 3600 * 24) + (Number(h) * 3600) + (Number(m) * 60) + Number(s);
    return seconds
}

export const parseDurationToH = (duration: string) => {

    const { d, h, m } = parseDuration(duration);
    const minsAsH = Number(m) > 0 ? `.${Number(m)/60}`.substring(2) : '';
    const hNum = (Number(d) * 24) + Number(h);

    return `${hNum}${minsAsH}`;
}

export const getDateTimeInDefFormatFromObject = (date: Date) => {
    const localDateString = date.toString();
    const localDateStringWithoutGMT = localDateString.split(' GMT')
    return localDateStringWithoutGMT[0];
}

export const removeSeconds = (dateTime: string) => {
    const dateTimeNoSecs = dateTime.substring(0, dateTime.length-3);
    return dateTimeNoSecs
}
const removeHoursAndMins = (dateTime: string) => {
    const date = dateTime.substring(0, dateTime.length-6);
    return date
}
const removeTime = (dateTime: string) => removeHoursAndMins(removeSeconds(dateTime));

export const getDateInAlternativeFormatFromObject = (date: Date) => {
    /**
     * This format is for typing a date string
     */
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export const getDateObjectFromAlternativeFormat = (date: string) => {
    const [year, month, day] = date.split('-');
    return new Date(Number(year), Number(month)-1, Number(day));
}

export const getDateInDefFormatFromObject = (date: Date) => removeTime(getDateTimeInDefFormatFromObject(date));


export const getDateInBackendInputStringFromLocalObject = (dateTime: Date) => {
    const utcDT = getUTCDateFromLocalDate(dateTime);
    const date = `${utcDT.getFullYear()}-${utcDT.getMonth() + 1}-${utcDT.getDate()}`;
    const minutes = utcDT.getMinutes();
    let minutesString;
    if (minutes < 10) {
        minutesString = `0${minutes}`;
    } else {
        minutesString = `${minutes}`;
    }
    const time =  `${utcDT.getHours()}:${minutesString}:00`;
    return date + ' ' + time;
}


export const getTimezoneOffsetInMs = () => {
    const timeOffsetMinutes = new Date().getTimezoneOffset();
    return timeOffsetMinutes * 60 * 1000;
}
/**
 *  dateTimeStringUTC: a datetime string without timezone info
 *  By default, date constructor assumes string is local time. 
 *  So if you are GMT -5, the date object will be 5 hours ahead of intended. 
 *  To get actual local, need to substract the timezone offset
 */
export const getLocalDateFromUTCDate = (date: Date) => {
    const dateInMilliseconds = date.getTime();
    const timeOffsetMillisecond = getTimezoneOffsetInMs();
    const localDate = new Date(dateInMilliseconds - timeOffsetMillisecond);
    return localDate
}


export const getUTCDateFromLocalDate = (date: Date) => {
    const dateInMilliseconds = date.getTime();
    const timeOffsetMillisecond = getTimezoneOffsetInMs();
    const localDate = new Date(dateInMilliseconds + timeOffsetMillisecond);
    return localDate
}


export const getLocalDateFromUTCString = (dateTimeStringUTC: string) => {
    const date = new Date(dateTimeStringUTC); 
    return getLocalDateFromUTCDate(date);
}


export const getLocalInDefFormatFromUtcString = (dateTimeStringUTC: string) => {
    const localDate = getLocalDateFromUTCString(dateTimeStringUTC);
    const localDateStringDefFormat = getDateTimeInDefFormatFromObject(localDate)
    return localDateStringDefFormat
};


export const getLocalInDefFormatNoSecsFromUtcString = (dateTimeStringUTC: string) => {
    const dateTime = getLocalInDefFormatFromUtcString(dateTimeStringUTC);
    return removeSeconds(dateTime)
}

export const getLocalInDefFormatNoTimeFromUtcString = (dateTimeStringUTC: string) => {
    const dateTimeNoSecs = getLocalInDefFormatNoSecsFromUtcString(dateTimeStringUTC);
    return removeHoursAndMins(dateTimeNoSecs)
}



//----------------------------------------------------------------
export const getDatesToTodayFrom = (dateTimeStringUTC: string) => {
    let res = [];
    const tomorrowInMs = new Date(new Date().getTime() + MILLIS_PER_DAY).getTime();
    let tempDate = getLocalDateFromUTCString(dateTimeStringUTC);
    while (tempDate.getTime() < tomorrowInMs) {
        res.push(getDateInDefFormatFromObject(tempDate));
        // increment to next day
        tempDate = new Date(tempDate.getTime() + MILLIS_PER_DAY);
    }
    return res
}

export const getPrevSundayFromDate = (date: Date) => {
    while (!getDateInDefFormatFromObject(date).toLowerCase().startsWith('sun')) {
        // increment to prev day
        date = new Date(date.getTime() - MILLIS_PER_DAY);
    }
    const sunday = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0);
    return sunday
}

export const getWeekFromDate = (date: Date) => {
    let res = [];
    // go to sunday
    let tempDate = getPrevSundayFromDate(date);
    // go to end of week
    while (!getDateInDefFormatFromObject(tempDate).toLowerCase().startsWith('sat')) {
        res.push(getDateInDefFormatFromObject(tempDate));
        // increment to next day
        tempDate = new Date(tempDate.getTime() + MILLIS_PER_DAY);
    }
    res.push(getDateInDefFormatFromObject(tempDate));
    return res
}
import { AnyAction } from 'redux';
import {
    START_SPINNER,
    STOP_SPINNER,
    START_BOXED_SPINNER,
    STOP_BOXED_SPINNER
} from '../actions/types';

export interface SpinnerState {
    isSpinning: boolean,
    boxedIsSpinning: boolean
}

const initialState: SpinnerState = {
    isSpinning: false,
    boxedIsSpinning: false,
}

export default function spinner(state = initialState, action: AnyAction) {
    switch (action.type) {
        case START_SPINNER:
            return {
                ...state,
                isSpinning: true,
            }
        case STOP_SPINNER:
            return {
                ...state,
                isSpinning: false,
            }
        case START_BOXED_SPINNER:
            return {
                ...state,
                boxedIsSpinning: true,
            }
        case STOP_BOXED_SPINNER:
            return {
                ...state,
                boxedIsSpinning: false,
            }
        default:
            return state;
    }
}
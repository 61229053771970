import { ReactComponent as Send } from './paper-plane.svg';
import { ICON_SIZE_LARGE } from 'src/globalConstants';
import { IconPropsType } from './type';


export const SendIcon = ({
        width = ICON_SIZE_LARGE,
        height = ICON_SIZE_LARGE,
        className = undefined,
        title = undefined,
    }: IconPropsType) => {
        return <Send title={title} width={width} height={height} className={className} />
    }

// React and router
import React, { useState, useEffect } from 'react';
// Actions
import { getBoolFromLocalStorage } from '../../../utils';
import { ToggleSwitch } from '../../forms_authentication/ToggleSwitch';

const IS_DARK_KEY = 'is-dark-key';

export const ThemeSwitch = () => {

    const isInitiallyDark = getBoolFromLocalStorage(IS_DARK_KEY, false);

    const [isDark, setIsDark] = useState(isInitiallyDark);

    // set is dark if true when first mounted
    useEffect(() => {
        if (isInitiallyDark) {
            const html = document.documentElement;
            html.classList.add('dark');
            setIsDark(true);
            localStorage.setItem(IS_DARK_KEY, 'true');
        }
    }, [isInitiallyDark])

    const changeTheme = () => {
        const html = document.documentElement;
        if (html.classList.value.includes('dark')) {
            html.classList.remove('dark');
            setIsDark(false);
            localStorage.setItem(IS_DARK_KEY, 'false');
        } else {
            html.classList.add('dark');
            setIsDark(true);
            localStorage.setItem(IS_DARK_KEY, 'true');
        }
    }

    return (
        <ToggleSwitch
            name="dark mode"
            isToggled={isDark}
            toggle={changeTheme}
            toggledIcon={`🌜`}
            untoggledIcon={`🌞`}
            iconX={5}
        />
    )
}

export default ThemeSwitch
import { REMOVE_MAIN_ALERT, APPEND_MAIN_ALERT } from '../actions/types';
import { AnyAction } from 'redux';


export type MainAlertType = {id: string, msg: JSX.Element};

export interface MainAlertState {
    msgs: MainAlertType[],
}

const initialState: MainAlertState = {
    msgs: [],
}

export default function mainAlert(state = initialState, action: AnyAction) {
    switch(action.type) {
        case APPEND_MAIN_ALERT:
            // append
            const msgsWithoutNewId = [...state.msgs].filter((msg) => msg.id !== action.payload.id);
            return {
                msgs: [
                    ...msgsWithoutNewId,
                    action.payload
                ]
            }
        case REMOVE_MAIN_ALERT:
            const newMsgs = [...state.msgs].filter((msg) => msg.id !== action.payload);
            return {
                msgs: newMsgs,
            }
        default:
            return state;
    }
}
import React from 'react';
// Style
import './ModernSpinner.scss';

const ModernSpinner = () => {
    return ( 
        <div className="circle">
            <svg className="circle__svg" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <circle className="circle__svg-circle" cx="15" cy="15" r="10"/>
            </svg>
        </div>
    )
}

export default ModernSpinner
/////////////////////
export const DELAY_TIME = 500;
export const SPINNER_MOUNT_TIME = 1000;
// export const SPINNER_MINIMUM_TIME = 200;
export const SPINNER_MINIMUM_TIME = 0;

// Icon sizes
export const ICON_SIZE_SMALL = "1rem";
export const ICON_SIZE_MEDIUM = "1.25rem";
export const ICON_SIZE_LARGE = "1.5rem";
export const ICON_SIZE_XLARGE = "2rem";

// Password strength
export const PASSWORD_MINIMUM_LENGTH = 8;
export const PASSWORD_MINIMUM_STRENGTH = 2; // strength 2 according to library => UI 3 bars

// Errors
export const INVALID_EMAIL_MESSAGE = 'Please enter a valid email.';
export const EMPTY_PASSWORD_MESSAGE = 'Please enter a valid password.';
export const EMPTY_MESSAGE_MESSAGE = 'Please enter a message.';


export const DELETE_PROJECT_ERROR_MESSAGE = 'The post was not deleted.'; // TODO: check if this is appropriate


export const INVALID_URL = 'Something went wrong. Make sure the content you are attempting to access is available.';

// Server errors
export const SERVER_OFFLINE_MESSAGE = 'Could not connect to server. We are working on this right now';
export const SERVER_ERROR_MESSAGE = 'Something went wrong. We are working on this right now';
export const THROTTLED_MESSAGE = (seconds) => `It seems you have accessed this resource more than we wanted you to. Try again in ${seconds} seconds`;
export const LONG_REQUEST_MESSAGE = 'Something went wrong. You may be asking for too specific or too much information';
// Data taken
export const EMAIL_TAKEN_MESSAGE = 'A user with that email already exists.';

// Inauthenticated
export const INAUTHENTICATED_MESSAGE = 'An authentication error occured. Please refresh the page and try logging in again.';

// authentication_basic

export const INCORRECT_CREDENTIALS_MESSAGE = 'The provided credentials led to an unsuccessful login.';


// login
export const UNVERIFIED_EMAIL_MESSAGE = 'Please verify your email address before logging in for the first time.';


//////////////////////////////
// authentication_passwords
// Request
export const SUCCESSFUL_PASSWORD_REQUEST_RESET_MESSAGE = 'An email has been sent to that address for password reset.';
export const UNSUCCESSFUL_PASSWORD_REQUEST_RESET_MESSAGE = 'This email is not in the database. Please ensure that you typed the email address correctly. If the issue persists, send us an email at.'; // TODO: add email

// Reset
export const SUCCESSFUL_PASSWORD_RESET_MESSAGE = 'Password has been successfully changed to the new password.';
export const UNSUCCESSFUL_PASSWORD_RESET_PERMISSION_MESSAGE = 'The page link is not valid - either the link has already successfully changed the password, or a typographical error has been introduced';

// Change
export const SUCCESSFUL_PASSWORD_CHANGE_MESSAGE = SUCCESSFUL_PASSWORD_RESET_MESSAGE;

// Shared
export const EMPTY_PASSWORD2_MESSAGE = 'Please re-enter a valid password.';
export const INCORRECT_PASSWORD2_MESSAGE = 'Your new passwords are not identical.';
export const EMPTY_OLD_PASSWORD_MESSAGE = 'Please enter your old password.';
export const INCORRECT_OLD_PASSWORD_MESSAGE = 'Your old password was entered incorrectly. Please enter it again.';
export const PASSWORD_SIMILAR_TO_EMAIL_MESSAGE = "The new password is too similar to the email";
//////////////////////////////
// gradly_signup

export const VERIFICATION_EMAIL_SENT_MESSAGE = 'A verification email has been sent to your email address for verification. Please verify within the next 24 hours.'; // 24 hours based on backend

///////////////////////
// profile_basic

export const PROFILE_SAVE_SUCCESSFUL_MESSAGE = 'Preferences saved successfully.';


export const EMPTY_LANGUAGE_ARRAY = 'Please delete unused language fields.';

// * personal_pages

export const SUCCESSFUL_SECURITY_INFO_CHANGE_ARRAY = 'Your security information was successfully changed';

// messenger

export const NO_MORE_MESSAGES_MESSAGE = 'There are no more old messages';





export const LIKERT_ARRAY = [
    { id: 'radioStronglyDisagree', value: 1, displayName: "Strongly Disagree" },
    { id: 'radioDisagree', value: 2, displayName: "Disagree" },
    { id: 'radioNeutral', value: 3, displayName: "Neutral" },
    { id: 'radioAgree', value: 4, displayName: "Agree" },
    { id: 'radioStronglyAgree', value: 5, displayName: "Strongly Agree" }
];

export const PRIVATE_DISPLAY_NAME = 'Confidential';


// localstorage obsufication
export const SOCIAL_LOGGED_KEY = 'oih34fopivh3giyhg3ph4tgpifj3w4fpg3i4pgh3hj5pwjti3pjwfpjwp35jh093hf';
export const TOKEN_KEY = '5o4ihgjp34jhgp3j24gpoj34pgojh35pojgpokdpoejgpi3rpgjopfjodpjvgop3jgv';
export const GUIDE_IS_ACTIVE_KEY = 'vioephrtgphj3p5gj390hg3p409p023jfpinrigpnj3jgponrvo3i4bg3gpnh';









/////////////////////
// Tab variabls
export const SITE_NAME = 'Gradsun';

/////////////////////
// Urls
// NOTE: if axios is adding suffix of current url, add '/' at beginning of request url
export const LANDING_PAGE = '/';
export const MATCHING_TABLE_PAGE_PREFIX = '/matching';
export const MATCHING_TABLE_PAGE_SUFFIX = '/:pageNumber';
export const MATCHING_TABLE_PAGE = MATCHING_TABLE_PAGE_PREFIX + MATCHING_TABLE_PAGE_SUFFIX;

export const BROWSING_PAGE = '/browse';
export const SUPERVISOR_PAGE_PREFIX = '/supervisors';
export const SUPERVISOR_PAGE = SUPERVISOR_PAGE_PREFIX + '/:supervisorId';

export const PROJECTS_PAGE = '/myprojects';
export const MATCHING_HOW_TO_PAGE = '/howto/matching';
export const PRICING_PAGE = '/pricing';
export const MANAGEMENT_PAGE = '/management';
export const UPDATE_PAGE = '/update';
export const HIGH_SCORES_PAGE_PREFIX = '/high-scores';
export const HIGH_SCORES_PAGE_DEFAULT = '/high-scores/1';
export const HIGH_SCORES_PAGE = `${HIGH_SCORES_PAGE_PREFIX}/:pageNumber?`

export const USER_SCORES_PAGE_PREFIX = '/high-scores/user';
export const USER_SCORES_PAGE = `${USER_SCORES_PAGE_PREFIX}/:userId?`

export const ACTIVITY_SCORES_PAGE_PREFIX = '/high-scores/activities';
export const ACTIVITY_SCORES_PAGE = `${ACTIVITY_SCORES_PAGE_PREFIX}/:activity?/:pageNumber?`


export const INDIVIDUAL_PLAN_PAGE = '/individual-plan';
export const CONTACT_PAGE = '/contact';
export const PRIVACY_PAGE = '/privacy';
export const TERMS_OF_SERVICE_PAGE = '/terms';
export const MISSION_PAGE = '/mission';
export const FAQ_PAGE = '/faq';

// subscriptions
export const SUBSCRIPTION_PAGE = '/subscription';
export const CHANGE_SUBSCRIPTIONS_PAGE = '/unsubscribe/:token';
// blog
export const BLOG_PAGE_PREFIX = '/blog';
export const BLOG_PAGE = BLOG_PAGE_PREFIX;
// export const BLOG_ARTICLE_PAGE = BLOG_PAGE_PREFIX + '/:articleName';
export const FINDING_THE_RIGHT_SUPERVISOR_PAGE = BLOG_PAGE_PREFIX + '/finding-the-right-graduate-school-supervisor';
export const WHAT_IS_GRADUATE_SCHOOL_PAGE = BLOG_PAGE_PREFIX + '/what-is-graduate-school';

// Registration
export const REGISTRATION_PAGE = '/register';
export const WAITLIST_PAGE = '/waitlist/:tokenKey';
export const REPORT_WAITLIST_PAGE = '/report/waitlist/:token';
export const LOGIN_ON_CONFIRMATION_PAGE = '/do-not-click/:token';
// Password
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const PASSWORD_RECOVER_PAGE = '/recover';
export const PASSWORD_RESET_PAGE = '/password-reset/:uidb/:token';
// Login and children
export const LOGIN_PAGE = '/login';

// Profile and children
export const PROFILE_BY_ID_PAGE_PREFIX = '/students';
export const PROFILE_BY_ID_PAGE_SUFFIX = '/:userID/:category?';

export const PROFILE_BY_ID_PAGE = PROFILE_BY_ID_PAGE_PREFIX + PROFILE_BY_ID_PAGE_SUFFIX;

export const PROJECT_BY_ID_PAGE_PREFIX = '/projects';
export const PROJECT_BY_ID_PAGE_SUFFIX = '/:projectId/:category?';

export const PROJECT_BY_ID_PAGE = PROJECT_BY_ID_PAGE_PREFIX + PROJECT_BY_ID_PAGE_SUFFIX;

// Reference
export const REFERENCE_PAGE = '/reference/:token'; // submitting a reference
export const REPORT_REFERENCE_PAGE = '/report/reference/:token';
export const STUDENT_REFERENCE_PAGE = '/student-reference/:userId'; // reading a reference

// Message

export const MESSENGER_PAGE = '/messages';
// personal_pages
export const SECURITY_PAGE = '/security';
export const ACCOUNT_MAIN_PAGE ='/account';

// payments page
export const SUPPORT_US_PAGE = '/support-us';

// admin page
export const ADMIN_PAGE = '/admin';


// you don't need to add dynamic content anyway because most of it is behind a login
// don't add pages that are in a private route
const sitemap = {
    "url": [
        {
          "loc": {"$t": "https://gradsun.com"},
          "lastmod": {"$t": "2021-06-28"},
        },
        {
          "loc": {"$t": "https://gradsun.com" + PROJECTS_PAGE},
          "lastmod": {"$t": "2021-06-28"},
        },
        {
          "loc": {"$t": "https://gradsun.com" + MATCHING_HOW_TO_PAGE},
          "lastmod": {"$t": "2021-06-28"},
        },
        {
          "loc": {"$t": "https://gradsun.com" + CONTACT_PAGE},
          "lastmod": {"$t": "2021-06-28"},
        },
        {
          "loc": {"$t": "https://gradsun.com" + PRIVACY_PAGE},
          "lastmod": {"$t": "2021-06-28"},
        },
        {
          "loc": {"$t": "https://gradsun.com" + TERMS_OF_SERVICE_PAGE},
          "lastmod": {"$t": "2021-06-28"},
        },
        {
          "loc": {"$t": "https://gradsun.com" + MISSION_PAGE},
          "lastmod": {"$t": "2021-06-28"},
        },
        {
            "loc": {"$t": "https://gradsun.com" + FAQ_PAGE},
            "lastmod": {"$t": "2021-06-28"},
        },
        {
          "loc": {"$t": "https://gradsun.com" + SUBSCRIPTION_PAGE},
          "lastmod": {"$t": "2021-06-28"},
        },
        {
            "loc": {"$t": "https://gradsun.com" + BLOG_PAGE},
            "lastmod": {"$t": "2021-06-28"},
        },
        {
            "loc": {"$t": "https://gradsun.com" + FINDING_THE_RIGHT_SUPERVISOR_PAGE},
            "lastmod": {"$t": "2021-06-28"},
        },
        {
          "loc": {"$t": "https://gradsun.com" + WHAT_IS_GRADUATE_SCHOOL_PAGE},
          "lastmod": {"$t": "2021-07-02"},
        },
        {
            "loc": {"$t": "https://gradsun.com" + REGISTRATION_PAGE},
            "lastmod": {"$t": "2021-06-28"},
        },
        {
            "loc": {"$t": "https://gradsun.com" + PASSWORD_RECOVER_PAGE},
            "lastmod": {"$t": "2021-06-28"},
        },
        {
            "loc": {"$t": "https://gradsun.com" + LOGIN_PAGE},
            "lastmod": {"$t": "2021-06-28"},
        },
        {
            "loc": {"$t": "https://gradsun.com" + SUPPORT_US_PAGE},
            "lastmod": {"$t": "2021-06-28"},
        }
    ],
}

export default sitemap
// View: React
import React, { useState } from 'react';
// Style
import './AlertList.scss';
// Icons
import { ReactComponent as CheckmarkIcon } from 'bootstrap-icons/icons/check-circle-fill.svg';
import { ReactComponent as ExclamationIcon } from 'bootstrap-icons/icons/exclamation-diamond-fill.svg';
import { ReactComponent as InfoIcon } from 'bootstrap-icons/icons/info-circle-fill.svg';
import CloseButton from '../buttons/CloseButton';


export interface BaseAlert {
    /** Bootstrap color of the alert.*/
    type: "success" | "danger" | "info",
}

interface AlertPropType extends BaseAlert {
    isCentered?: boolean,
    hideCallback?: () => void,
    /** the error message */
    children: JSX.Element | string
}
const defaultHideCallBack = () => {}
export const Alert = ({
        type,
        isCentered = false,
        hideCallback = defaultHideCallBack,
        children
    }: AlertPropType) => {
    const [showAlert, setShowAlert] = useState(true);

    if (!showAlert) {
        return null
    }

    let icon;
    switch(type) {
        case 'success':
            icon = <CheckmarkIcon />
            break
        case 'danger':
            icon = <ExclamationIcon />
            break
        case 'info':
            icon = <InfoIcon />
            break
        default:
            icon = null;
    }

    const role = type === "danger" ? "alert" : undefined;

    return (
        <div 
            className={`alert-${type} p-3 d-flex align-items-center justify-content-center`}
            role={role}
            aria-label={typeof children === 'string' ? children: undefined}
        >
            {isCentered ? 
                <div className="d-flex ms-auto">
                    <div className="me-3 d-flex align-items-center">{icon}</div>
                    {children}
                </div>
            :
                <div className="d-flex">
                    <div className="me-3 d-flex align-items-center">{icon}</div>
                    {children}
                </div>
            }
            <CloseButton handleClose={() => {
                hideCallback()
                setShowAlert(false)
            }} ariaLabel="Close alert"/>
        </div>
    )
}

export default Alert
// View: React and Router-DOM
import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
// State: React-Redux
import { Provider as StoreProvider } from "react-redux";
import store from "./reducers/store";
// Alerts: React-alert
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/alerts_dropdown/AlertTemplate";
// layout
import PageSpecificMeta from "./components/page_state/PageSpecificMeta";
import DynamicFavicon from "./components/page_state/DynamicFavicon";

// Monitors
import TabVisibilityMonitor from "./components/tab_visibility_monitor/TabVisibilityMonitor";

// Style
import "./App.scss";
// Actions
import { loadUser } from "./actions/authentication_basic";
// Constants
import { DROPDOWN_ALERTS_OPTIONS } from "./components/alerts_dropdown/localConstants";

import { MainRoutes } from "./MainRoutes";
import { AnyAction } from "redux";
import CustomerServiceContainer from "./components/chatbox/CustomerServiceContainer";
import ConversationTrackerContainer from "./components/messenger/ConversationTrackerContainer";
import DoneRoutes from "./DoneRoutes";

// import Spinner from './spinner/Spinner';

// alerts_dropdown
// import DropdownAlerts from './alerts_dropdown/DropdownAlerts';
const DropdownAlerts = lazy(
  () => import("./components/alerts_dropdown/DropdownAlerts")
);

const ErrorBoundary = lazy(
  () => import("./components/error_boundary/ErrorBoundary")
);

// const AlertProvider = lazy(() => import("react-alert").then(module => ({ default: module.Provider })));

// TODO: add spinner
// const Spinner = lazy(() => import('./spinner/Spinner'));

export default class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser() as unknown as AnyAction);
  }

  render() {
    return (
      <StoreProvider store={store}>
        <AlertProvider template={AlertTemplate} {...DROPDOWN_ALERTS_OPTIONS}>
          <BrowserRouter>
            <Suspense fallback={<div></div>}>
              <ErrorBoundary>
                <DynamicFavicon />
                <PageSpecificMeta />
                <TabVisibilityMonitor />
                {/* <ConversationTrackerContainer /> */}
                <Suspense fallback={<div></div>}>
                  <DropdownAlerts />
                </Suspense>
                {/* <CustomerServiceContainer /> */}
                {/* <Suspense fallback={<div></div>}>
                                    <Spinner />
                                </Suspense> */}
                {/* Routes can be bundled under one suspense because:
                 ** suspense won't wait for all the routes. Its as if inactive routes do not exists
                 */}
                <Suspense fallback={<div></div>}>
                  <MainRoutes />
                  {/* <DoneRoutes /> */}
                </Suspense>
              </ErrorBoundary>
            </Suspense>
          </BrowserRouter>
        </AlertProvider>
      </StoreProvider>
    );
  }
}

import { combineReducers } from 'redux';

import alertsDropdown from './alerts_dropdown';
import mainAlert from './main_alert';
import authenticationBasic from './authentication_basic';
import authenticationPassword from './authentication_passwords';
import authenticationSocial from './authentication_social';
import gradlySignup from './gradly_signup';
import errors from './errors';
import matchingTable from './matching_table';
import messenger from './messenger';
import preferenceData from './preference_data';
import profileBasic from './profile_basic';
import spinner from './spinner';
import tabVisibility from './tab_visibility';

import { AlertsDropdownState } from './alerts_dropdown';
import { MainAlertState } from './main_alert';
import { AuthenticationBasicState } from './authentication_basic';
import { AuthenticationPasswordState } from './authentication_passwords';
import { AuthenticationSocialState } from './authentication_social';
import { ErrorsState } from './errors';
import { GradlySignupState } from './gradly_signup';
import { GuideState } from './guide';
import { MatchingTableState } from './matching_table';
import { MessengerState } from './messenger';
import { PreferenceDataState } from './preference_data';
import { ProfileBasicState } from './profile_basic';
import { SpinnerState } from './spinner';
import { TabVisibilityState } from './tab_visibility';



export interface ReduxState {
    alertsDropdown: AlertsDropdownState,
    mainAlert: MainAlertState,
    authenticationBasic: AuthenticationBasicState,
    authenticationPassword: AuthenticationPasswordState,
    authenticationSocial: AuthenticationSocialState,
    errors: ErrorsState,
    gradlySignup: GradlySignupState,
    guide: GuideState,
    matchingTable: MatchingTableState,
    messenger: MessengerState,
    preferenceData: PreferenceDataState,
    profileBasic: ProfileBasicState,
    spinner: SpinnerState,
    tabVisibility: TabVisibilityState
}

export default combineReducers({
    alertsDropdown,
    mainAlert,
    authenticationBasic,
    authenticationPassword,
    authenticationSocial,
    gradlySignup,
    errors,
    matchingTable,
    messenger,
    preferenceData,
    profileBasic,
    spinner,
    tabVisibility,
});
import { AnyAction } from 'redux';
import {
    SET_AS_PROJECT, SET_AS_STUDENT,
    SET_AS_OWNER, SET_AS_NOT_OWNER,
    SET_PREFERENCE_DATA,
    MODIFY_PREFERENCE_DATA
} from '../actions/types';


export type CvInfoDataType = {
    cv_url: string,
    user_id: number,
    cv_private: boolean,
}
export type LearningPriorityType = {
    id: number,
    rank_one: number,
    rank_two: number,
    rank_three: number,
    rank_four: number,
}[]
export type PreferenceDataFieldsType = number | string | boolean | object | LearningPriorityType | CvInfoDataType | undefined
export interface PreferenceDataState {
    isProject: boolean,
    isOwner: boolean,
    cv_info?: CvInfoDataType,
    [index: string]: PreferenceDataFieldsType | CvInfoDataType
}

const initialState: PreferenceDataState = {
    isProject: false,
    isOwner: false,
    // payload from set_preference_data includes a lot of other variables
}

export default function preferenceData(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SET_AS_PROJECT:
            return {
                ...state,
                isProject: true,
            }
        case SET_AS_STUDENT:
            return {
                ...state,
                isProject: false,
            }
        case SET_AS_OWNER:
            return {
                ...state,
                isOwner: true,
            }
        case SET_AS_NOT_OWNER:
            return {
                ...state,
                isOwner: false,
            }
        case SET_PREFERENCE_DATA:
            return {
                ...state,
                ...action.payload
            }
        case MODIFY_PREFERENCE_DATA:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        default:
            return state;
    }
}
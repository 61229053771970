import { ActionCreatorType } from '../types';
import {
    START_SPINNER,
    STOP_SPINNER,
    START_BOXED_SPINNER,
    STOP_BOXED_SPINNER
} from './types';


/** Creates redux action START_SPINNER.
 ** SID: 0,009 Show spinner during API calls
*/
export const startSpinner = () => {
    return {
        type: START_SPINNER,
    }
}

/** Creates redux action STOP_SPINNER.
 ** SID: 0,009 Show spinner during API calls
*/
export const stopSpinner = () => {
    return {
        type: STOP_SPINNER,
    }
}

/** dispatches startSpinner.
 ** SID: 0,009 Show spinner during API calls
*/
export const startSpinnerFunc = (): ActionCreatorType => dispatch => {
    dispatch(startSpinner());
}

/** dispatches stopSpinner.
 ** SID: 0,009 Show spinner during API calls
*/
export const stopSpinnerFunc = (): ActionCreatorType => dispatch => {
    dispatch(stopSpinner());
}


////////////////////////////////////////
// Deprecated : boxed spinner -> could lead to memory leaks because it allows user to leave page.

/** Deprecated: Creates action START_BOXED_SPINNER.
 ** SID: 0,009 Show spinner during API calls
*/
export const startBoxedSpinner = () => {
    return {
        type: START_BOXED_SPINNER,
    }
}

/** Deprecated: Creates action STOP_BOXED_SPINNER.
 ** SID: 0,009 Show spinner during API calls
*/
export const stopBoxedSpinner = () => {
    return {
        type: STOP_BOXED_SPINNER,
    }
}

/** Deprecated: Dispatches startBoxedSpinner.
 ** SID: 0,009 Show spinner during API calls
*/
export const startBoxedSpinnerFunc = (): ActionCreatorType => dispatch => {
    dispatch(startBoxedSpinner());
}

/** Deprecated: Dispatches stopBoxedSpinner.
 ** SID: 0,009 Show spinner during API calls
*/
export const stopBoxedSpinnerFunc = (): ActionCreatorType => dispatch => {
    dispatch(stopBoxedSpinner());
}
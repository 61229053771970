// View: React and Router-DOM
import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'src/hooks';
import Modal from '../modal';
import './ToolTip.scss';

export interface ToolTipModalPropsType {
    isAboveElement?: boolean,
    isRightJustified?: boolean,
    children: JSX.Element | string
}
export const ToolTipModal = ({
        children, 
        isAboveElement = false,
        isRightJustified = false
    }: ToolTipModalPropsType) => {

    const toolTipRef = useRef<HTMLDivElement>(null);

    // const windowScrollY = useWindowScrollYPosition();

    const [top, setTop] = useState(-100);
    const [left, setLeft] = useState(-100);
    const [right, setRight] = useState(-100);

    const windowSize = useWindowSize();

    useEffect(() => {
        if (toolTipRef && toolTipRef.current) {
            const elBoundingRect = toolTipRef.current.getBoundingClientRect();
            const elLeft = elBoundingRect.left;
            const elRight = elBoundingRect.right;
            const elTop = elBoundingRect.top;
            setTop(elTop);
            setLeft(elLeft);
            setRight(windowSize.width - elRight);
        }
    }, [windowSize.width]);

    const horizontal = isRightJustified ? { "right" : `${right}px`} : { "left" : `${left}px`}
    return (
        <div ref={toolTipRef}>
            <Modal>
                <div className="tooltip highest-z position-absolute"
                    style={{"top": `${top}px`, ...horizontal}}
                >
                    {children}
                </div>
            </Modal>
        </div>
    )
}

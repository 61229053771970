import './ListSentence.scss';

type ListSentencePropsType<Type> = {
    list: Type[],
    children: (el: Type) => JSX.Element
}
const ListSentence = <Type,>({
        list,
        children
    }: ListSentencePropsType<Type>) => {

    return (
        <>
            {list.map((el, index) => {
                return (
                    <>
                        {((index === (list.length - 1)) && list.length > 1)? ' and ' : ''}
                        {children(el)}
                        {(el == null || index === list.length - 1 || (list.length <= 2)) ? '' : `, `}
                    </>
                )
            })}
        </>
    )
}

export default ListSentence
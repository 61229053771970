import { useEffect, useState } from "react";

// Only works on scrolling main window
export function useWindowScrollYPosition() {
    const [scrollPosition, setPosition] = useState(0);

    useEffect(() => {
        function updatePosition() {
            setPosition(window.pageYOffset);
        }
        window.addEventListener('scroll', updatePosition);
        return () => window.removeEventListener('scroll', updatePosition);
    });

    return scrollPosition;
}


export function useWindowSize() {
    const [windowSize, setWindowSize] = useState<{width: number, height: number}>({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
      
        // Add event listener
        window.addEventListener("resize", handleResize);
        
        // Call handler right away so state gets updated with initial window size
        handleResize();
        
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
  
    return windowSize;
}
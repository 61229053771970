import deepEqual from 'lodash.isequal'; 
import React from 'react';
import { JsonCopy } from '../types';

import { getDateInAlternativeFormatFromObject, getWeekFromDate, getDateInDefFormatFromObject, getDatesToTodayFrom, getLocalDateFromUTCString, getLocalInDefFormatFromUtcString, getLocalInDefFormatNoSecsFromUtcString, getLocalInDefFormatNoTimeFromUtcString } from './dates';
export { getDateInAlternativeFormatFromObject, getWeekFromDate, getDateInDefFormatFromObject, getDatesToTodayFrom, getLocalDateFromUTCString, getLocalInDefFormatFromUtcString, getLocalInDefFormatNoSecsFromUtcString, getLocalInDefFormatNoTimeFromUtcString };

export const capitalize = (text: string) => {
    if (text.length < 1) {
        return text
    }
    const onlyFirstLetterIsCapital = text[0].toUpperCase().concat(text.slice(1).toLowerCase());
    const firstLetterAndIAreCapital = onlyFirstLetterIsCapital.replace(/ i /g, " I ");
    return firstLetterAndIAreCapital
}

export const capitalizeSentence = (sentence: string) => sentence.replace(/\b\w/g, l => l.toUpperCase())

export const removeHyphens = (string: string) => string.replace(/-/g, " ");
export const reverseRemoveHyphens = (string: string) => string.replace(/ /g, "-");

export const removeHyphensThenCapitalize = (string: string) => capitalize(removeHyphens(string))
export const removeHyphensThenCapitalizeSentence = (string: string) => capitalizeSentence(removeHyphens(string));

export const reverseRemoveHyphensThenCapitalize = (string: string) => (string.toLowerCase()).replace(/ /g, "-");


export const replaceUnderscoresWithHyphens = (string: string) => string.replace(/_/g, "-");

export const objectIsInArray = (potentiallyNewMessage: object, arrOfOldMessages: object[]) => {
    for (const oldMessage of arrOfOldMessages) {
        const areEqual = deepEqual(potentiallyNewMessage, oldMessage);
        if (areEqual) {
            return true
        }
    }
    return false
}


export const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}


export const getBoolFromLocalStorage = (key: string, default_checked: boolean) => {
    let checked;
    if (localStorage.getItem(key) == null) {
        checked = default_checked;
    } else {
        checked = (localStorage.getItem(key) === 'true');
    }
    return checked
}

export const toggleIfEnter = (e: React.KeyboardEvent, callback: () => void) => {
    if (e.key ==="Enter" || e.keyCode === 13) {
        callback()
    }
}

// * checks whether profile data is empty
export const isEmpty = (value: any) => {
    if (value == null) {
        return true
    }

    if (Array.isArray(value)) {
        if (value.length < 1) {
            return true
        } else {
            return false
        }
    }
    if (typeof value === 'object') {
        if (('value' in value) && isEmpty(value.value)) {
            return true
        }
        if ('cv_url' in value && isEmpty(value.cv_url)) {
            return true
        }
    }
    if (value === '') {
        return true
    }
    return false
}


// truncuate string and remove last word if it's excessive
export const truncateString = (string: string, maxLength: number) => {
    if (string.length < maxLength) {
        return string;
    }
    let trimmedString = string.substr(0, maxLength);
    //re-trim if we are in the middle of a word
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
    return trimmedString + '...';
}

export const openInNewTab = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null, url: string) => {
    if (e != null) {
        e.preventDefault();
    }
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}


export const oxfordify = (arr: string[] | number[], prefix = '') => {
    // prefixing doesn't quite work
    if (arr.length === 0) {
        return '';
    }
    if (arr.length === 1) {
        return `${prefix + arr[0]}`
    }
    let tempArray = JsonCopy(arr);
    tempArray[tempArray.length - 1] = `and ${prefix + arr[tempArray.length - 1]}`;
    return prefix + tempArray.join(', ' + prefix)
}

export const getIsCurrentUser = (sender: number, userID: string | null) => {
    let isCurrentUser;
    if (userID) {
        isCurrentUser = sender === Number(userID);
    } else {
        // if no user ID (logged out, anonymous conversation, would be sending messages as null)
        isCurrentUser = sender == null;
    }
    return isCurrentUser
}

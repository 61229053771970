// Hooks
import { useIsSmallScreen } from '../../../hooks';
// Components
import SideBarSmallScreen from './SideBarSmallScreen';
import SideBarLargerScreen from './SideBarLargerScreen';
// style
import './SideBar.scss';


/**
** SID: 0,027
 * @author Nassir Al-Khishman 
*/
export const SideBar = () => {
    const isSmallScreen = useIsSmallScreen();

    if (isSmallScreen) {
        return <SideBarSmallScreen />
    } else {
        return <SideBarLargerScreen />
    }
}

export default SideBar
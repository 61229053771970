import {
    EMAIL_TAKEN_MESSAGE,
    INVALID_EMAIL_MESSAGE,
    UNSUCCESSFUL_PASSWORD_REQUEST_RESET_MESSAGE,
    EMPTY_PASSWORD_MESSAGE,
    EMPTY_OLD_PASSWORD_MESSAGE,
    INCORRECT_OLD_PASSWORD_MESSAGE,
    EMPTY_PASSWORD2_MESSAGE,
    INCORRECT_PASSWORD2_MESSAGE,
    PASSWORD_SIMILAR_TO_EMAIL_MESSAGE,
    EMPTY_MESSAGE_MESSAGE,
    INAUTHENTICATED_MESSAGE,
    SERVER_ERROR_MESSAGE,
    THROTTLED_MESSAGE,
    LONG_REQUEST_MESSAGE,
    EMPTY_LANGUAGE_ARRAY,
    INCORRECT_CREDENTIALS_MESSAGE,
    NO_MORE_MESSAGES_MESSAGE,
    UNVERIFIED_EMAIL_MESSAGE,
    INVALID_URL
} from '../globalConstants';

import {
    AUTH_ERROR,
} from '../actions/types';

import { asyncFail } from '../actions/errors';
import { ActionCreatorType, DispatchType, HandleBackendErrorsType } from '../types';


type MsgObjectType = {
    [key: string]: any
}
const getGenericErrors = (
        status: number,
        msgObject: MsgObjectType,
        dispatch: DispatchType
    ) => {
    let genericErrors = [];
    const keys = Object.keys(msgObject);

    if (status === 401) {
        // TODO: test this by using token with short expiry time
        dispatch({ type: AUTH_ERROR })
        genericErrors.push(INAUTHENTICATED_MESSAGE);
    }
    if (status === 429) {
        const TIME_TILL_RETRY = msgObject['detail'].split('in ')[1];
        genericErrors.push(THROTTLED_MESSAGE(TIME_TILL_RETRY));
    }

    if (status === 431) {
        genericErrors.push(LONG_REQUEST_MESSAGE);
    }

    if (keys.includes('error')) {
        genericErrors.push(msgObject['error']);
    }

    if (keys.includes('detail')) {
        genericErrors.push(msgObject['detail']);
    }

    if (keys.includes('non_field_errors')) {
        let message = msgObject['non_field_errors'];
        if (message.toString() === 'Unable to log in with provided credentials.') {
            genericErrors.push(INCORRECT_CREDENTIALS_MESSAGE);
        } else if (message.toString() === 'E-mail is not verified.') {
            genericErrors.push(UNVERIFIED_EMAIL_MESSAGE);
        } else {
            genericErrors.push(message);
        }
    }

    if (keys.includes('no_messages')) {
        let message = msgObject['no_messages'];
        if (message.toString() === "There are no messages between 'first' and 'last' (inclusive)") {
            genericErrors.push(NO_MORE_MESSAGES_MESSAGE);
        }
    }

    if (keys.includes('cookies')) {
        let message = msgObject['cookies'];
        genericErrors.push(message);
    }

    return genericErrors
}


const modifySpecificErrors = (
        msgObject: MsgObjectType,
    ) => {
    const keys = Object.keys(msgObject);

    if (keys.includes('email')) {
        let message = msgObject['email'];
        if (message.toString() === 'A user is already registered with this e-mail address.') { 
            msgObject['email'] = EMAIL_TAKEN_MESSAGE;
        }
        if (message.toString().search(/email already exists/i) > -1) { 
            msgObject['email'] = EMAIL_TAKEN_MESSAGE;
        }
        if (message.toString().search(/waiter/i) > -1) {
            msgObject['email'] = EMAIL_TAKEN_MESSAGE;
        }
        if (message.toString() === 'This field may not be blank.' || message.toString() === 'This field is required.') {
            msgObject['email'] = INVALID_EMAIL_MESSAGE;
        }
        if (message.toString() === 'Enter a valid email address.') {
            msgObject['email'] = INVALID_EMAIL_MESSAGE;
        } 
        if (message.toString() === 'This email is not in the database') {
            msgObject['email'] = UNSUCCESSFUL_PASSWORD_REQUEST_RESET_MESSAGE;
        }
    }

    if (keys.includes('password')) {
        let message = msgObject['password'];
        if (message.toString() === 'This field may not be blank.') {
            msgObject['password'] = EMPTY_PASSWORD_MESSAGE;
        }
    }
    
    if (keys.includes('old_password')) {
        let message = msgObject['old_password'];
        if (message.toString() === 'This field may not be blank.') {
            msgObject['old_password'] = EMPTY_OLD_PASSWORD_MESSAGE;
        }
        if (message.toString() === 'Your old password was entered incorrectly. Please enter it again.') {
            msgObject['old_password'] = INCORRECT_OLD_PASSWORD_MESSAGE;
        }
    }
    
    if (keys.includes('new_password2')) {
        let message = msgObject['new_password2'];
        if (message.toString() === 'This field may not be blank.') {
            msgObject['new_password2'] = EMPTY_PASSWORD2_MESSAGE;
        }
        if (message.toString() === 'The two password fields didn’t match.') {
            msgObject['new_password2'] = INCORRECT_PASSWORD2_MESSAGE;
        }
        // backend sends it to new_password2, but it would make more sense if this one was in new_password
        if (message.toString() === 'The password is too similar to the email.') {
            msgObject['new_password'] = PASSWORD_SIMILAR_TO_EMAIL_MESSAGE; 
        }
    }

    if (keys.includes('languages')) {
        let message = msgObject['languages'];
        if (message.toString() === "The '' language is currently not offered") { 
            msgObject['languages'] = EMPTY_LANGUAGE_ARRAY;
        }
    }

    if (keys.includes('message')) {
        let message = msgObject['message'];
        if (message.toString() === 'This field is required.') {
            msgObject['message'] = EMPTY_MESSAGE_MESSAGE;
        }
        if (message.toString() === 'This field may not be blank.') {
            msgObject['message'] = EMPTY_MESSAGE_MESSAGE;
        }
    }
    
}


/** Handles errors from the backend to make them easier to consume by the react UI.
*/
export const preprocessBackendErrors = (
        handleBackendErrors: HandleBackendErrorsType,
        error: any,
        changeIcon = true
    ): ActionCreatorType=> dispatch => {
    if (changeIcon) {
        dispatch(asyncFail())
    }
    debugger;
    if (error.response == null) {
        return handleBackendErrors({error: 'Something went wrong'}); // no code because it could be not network related
    }
    let msgObject = error.response.data as MsgObjectType | any[];
    const status = error.response.status;
    const url = error.config ? error.config.url : "missing" as string;
    if (url.includes('null')) {
        msgObject = {
            error: INVALID_URL,
        }
        return handleBackendErrors(msgObject);
    }
    
    // something went wrong or server offline
    if ((Array.isArray(msgObject)) || status === 500 || (typeof msgObject === 'string' || msgObject instanceof String)) {
        msgObject = {
            error: SERVER_ERROR_MESSAGE,
        }
        return handleBackendErrors(msgObject);
    }

    // put generic errors under 'detail' key. These can be placed anywhere in page
    const genericErrors = getGenericErrors(status, msgObject, dispatch);
    msgObject['error'] = genericErrors;

    // put other errors under specific keys. These are better placed next to the source of error. modify msgObject
    modifySpecificErrors(msgObject)
    // Pass new msgs into callback
    handleBackendErrors(msgObject);
}
////////////////////////////////////////
//alerts_dropdown
export const CREATE_ALERT = 'CREATE_ALERT';

// main_alert
export const APPEND_MAIN_ALERT = 'APPEND_MAIN_ALERT';
export const REMOVE_MAIN_ALERT = 'REMOVE_MAIN_ALERT';

////////////////////////////////////////
//authentication_basic
export const INITIATE_LOGIN_SUCCESS = 'INITIATE_LOGIN_SUCCESS';
export const INITIATE_LOGIN_FAIL = 'INITIATE_LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const SET_TOKEN = 'SET_TOKEN';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_IS_OLD = 'LOGOUT_IS_OLD';
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';

////////////////////////////////////////
//authentication_passwords
export const PASSWORD_REQUEST_RESET_SUCCESS = 'PASSWORD_REQUEST_RESET_SUCCESS';
export const PASSWORD_REQUEST_RESET_FAIL = 'PASSWORD_REQUEST_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAIL = 'PASSWORD_CHANGE_FAIL';

////////////////////////////////////////
//authentication_social
//FB
export const FB_LOGIN_SUCCESS = 'FB_LOGIN_SUCCESS';
export const FB_LOGIN_FAIL = 'FB_LOGIN_FAIL';
export const FB_API_SUCCESS = 'FB_API_SUCCESS';
export const FB_API_FAIL = 'FB_API_FAIL';
//Google
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAIL = 'GOOGLE_LOGIN_FAIL';
export const GOOGLE_API_SUCCESS = 'GOOGLE_API_SUCCESS';
export const GOOGLE_API_FAIL = 'GOOGLE_API_FAIL';
// linkedIn
export const LINKED_IN_AUTHORIZATION_SUCCESS = 'LINKED_IN_AUTHORIZATION_SUCCESS';
export const LINKED_IN_AUTHENTICATION_FAIL = 'LINKED_IN_AUTHENTICATION_FAIL';


////////////////////////////////////////
//gradly_signup
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

////////////////////////////////////////
//errors
export const ASYNC_FAIL = 'ASYNC_FAIL';
export const ASYNC_RESET = 'ASYNC_RESET';

// * guide is deprecated: not user friendly
export const ACTIVATE_GUIDE = 'ACTIVATE_GUIDE';
export const INACTIVATE_GUIDE = 'INACTIVATE_GUIDE';

//* matching table
export const GET_CV_ACCESS_LISTS_SUCCESSFUL = 'GET_CV_ACCESS_LISTS_SUCCESSFUL';

// * Messenger
export const GET_CONVERSATION_TRACKER = 'GET_CONVERSATION_TRACKER';


////////////////////////////////////////
//profile_basic

export const SET_ERROR_ARRAY = 'SET_ERROR_ARRAY';
export const GOOD_TRUE = 'GOOD_TRUE';
export const GOOD_FALSE = 'GOOD_FALSE';
export const RESET_PREFERENCE_ALERTS = 'RESET_PREFERENCE_ALERTS';

// preferences page
export const SET_AS_STUDENT = 'SET_AS_STUDENT';
export const SET_AS_PROJECT = 'SET_AS_PROJECT';
export const SET_AS_OWNER = 'SET_AS_OWNER';
export const SET_AS_NOT_OWNER = 'SET_AS_NOT_OWNER';
export const SET_PREFERENCE_DATA = 'SET_PREFERENCE_DATA';
export const MODIFY_PREFERENCE_DATA = 'MODIFY_PREFERENCE_DATA';
////////////////////////////////////////
//spinner
export const START_SPINNER = 'START_SPINNER';
export const STOP_SPINNER = 'STOP_SPINNER';
export const START_BOXED_SPINNER = 'START_BOXED_SPINNER';
export const STOP_BOXED_SPINNER = 'STOP_BOXED_SPINNER';

// * Tab visibility
export const INACTIVATE_TAB = 'INACTIVATE_TAB';
export const ACTIVATE_TAB = 'ACTIVATE_TAB';
export const SET_SUB = 'SET_SUB';

// messenger

export const CLEAR_CURRENTLY_SELECTED_CONVERSATION = 'CLEAR_CURRENTLY_SELECTED_CONVERSATION';
export const SET_CURRENTLY_SELECTED_CONVERSATION = 'SET_CURRENTLY_SELECTED_CONVERSATION';
export const SET_FULL_CONVERSATION = 'SET_FULL_CONVERSATION';
export const CONVERSATION_DOES_NOT_EXIST = 'CONVERSATION_DOES_NOT_EXIST';
export const LOADING_NEW_CONVERSATION = 'LOADING_NEW_CONVERSATION';
export const SET_POSTING_TITLE = 'SET_POSTING_TITLE';


export type PaginationType = {
    limit: number,
    offset: number,
}
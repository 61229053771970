import { createStore, applyMiddleware } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer from '.';

export const middleware = [thunk];

const initialState = {};

const devTools = (
    process.env.NODE_ENV === "production" ? 
        applyMiddleware(...middleware)
    :
        composeWithDevTools({trace: true})(applyMiddleware(...middleware))
);

const store = createStore(rootReducer, initialState, devTools);

// Was not able to infer
// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch

export default store;
// icons
import { ICON_SIZE_LARGE } from "../../../globalConstants";
import { NavLink } from "react-router-dom";
// hooks
import { useLocationIsProfile } from "./hooks";
// components
import TableOfContentsAutomatic from "../../common/TableOfContentsAutomatic";
import { SIDE_BAR_NAVS } from "./constants";



const SideBarSmallScreen = () => {
    
    const sideBarStyle = {
        "top": `0px`
    };

    const locationIsProfilePages = useLocationIsProfile();

    return (
        <nav
            className="second-lowest-z w-100"
            style={sideBarStyle}
            id="sidebar"
        >
            {locationIsProfilePages ?
                <ul id="sidebar-sm-content">
                    {SIDE_BAR_NAVS.map((sideBarNav) => {
                        return (
                            <li>
                                <NavLink to={sideBarNav.url} className={"sideBar-navlink navigation-colors justify-content-center"} activeClassName="active">
                                    <sideBarNav.icon title={sideBarNav.title} height={ICON_SIZE_LARGE} width={ICON_SIZE_LARGE} className="me-3"/>
                                    {sideBarNav.title}
                                    {<sideBarNav.extra />}
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            :
                <TableOfContentsAutomatic
                    hidden={false}
                />
            }
        </nav>
    )
}

export default SideBarSmallScreen
import { useLocation } from "react-router";
import { SIDE_BAR_NAVS } from "./constants";

/**
 * Determine whether to show profile page nav
*/
export function useLocationIsProfile() {
   
    const location = useLocation();
    const profilePaths = SIDE_BAR_NAVS.map((nav) => nav.url);
    if (profilePaths.includes(location.pathname)) {
        return true
    }
    return false;
}
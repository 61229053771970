// View: React and Router-DOM
import React from 'react'
import { Route, Redirect } from 'react-router-dom';
// State: React-Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { createDropdownError } from '../../actions/alerts_dropdown';
// Constants
import {
    LOGIN_PAGE
} from '../../globalConstants';
import { ReduxState } from '../../reducers';


export type PrivateRoutePropsType = {
    /** The path to display when loading.*/
    pathDisplay?: string,
    /** The Component.*/
    children: JSX.Element,
    /** rest */
    [index: string]: any
}

/** Encapsulate routes that require authentication
 ** SID: 0,003 and 0,004
 ** Avoids redirect loops
 * @author Nassir Al-Khishman 
*/
const PrivateRoute = ({
        pathDisplay = "that page",
        children,
        ...rest
    }: PrivateRoutePropsType) => {//component is within element tag

    const isLoading = useSelector((state: ReduxState) => state.authenticationBasic.isLoading);
    const isAuthenticated = useSelector((state: ReduxState) => state.authenticationBasic.isAuthenticated);
    const recentlyLoggedOut = useSelector((state: ReduxState) => state.authenticationBasic.recentlyLoggedOut);

    const dispatch = useDispatch();

    return (
        <Route
            {...rest}
            render={() => {
                if (isLoading) {
                    return <div></div>
                } else if (!isAuthenticated) {
                    if (!recentlyLoggedOut) {
                        dispatch(createDropdownError(`Please login before attempting to access ${pathDisplay}`));
                    }
                    return <Redirect push to={LOGIN_PAGE} />
                } else {
                    return children;
                }
            }}
        />
    )
};

export default PrivateRoute;
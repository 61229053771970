import { AnyAction } from 'redux';
import {
    FB_API_SUCCESS,
    FB_API_FAIL,
    FB_LOGIN_SUCCESS,
    FB_LOGIN_FAIL,
    GOOGLE_LOGIN_SUCCESS,
    GOOGLE_LOGIN_FAIL,
    GOOGLE_API_SUCCESS,
    GOOGLE_API_FAIL
} from '../actions/types';


export interface AuthenticationSocialState {
    fbData: object,
    fbApiData: object,
    googleData: object,
    googleApiData: object,
}

const initialState: AuthenticationSocialState = {
    fbData: {},
    fbApiData: {},
    googleData: {},
    googleApiData: {}
}


export default function authenticationSocial(state = initialState, action: AnyAction) {
    switch (action.type) {
        case FB_LOGIN_SUCCESS:
            return {
                ...state,
                fbData: action.payload,
            }
        case FB_LOGIN_FAIL:
            return {
                ...state,
                fbData: {}
            };
        case FB_API_SUCCESS:
            return {
                ...state,
                fbApiData: action.payload,
            }
        case FB_API_FAIL:
            return {
                ...state,
                fbApiData: {}
            };
        case GOOGLE_LOGIN_SUCCESS:
            return {
                ...state,
                googleData: action.payload,
            }
        case GOOGLE_LOGIN_FAIL:
            return {
                ...state,
                googleData: {}
            };
        case GOOGLE_API_SUCCESS:
            return {
                ...state,
                googleApiData: action.payload,
            }
        case GOOGLE_API_FAIL:
            return {
                ...state,
                googleApiData: {}
            };
        default:
            return state;
    }
}
import { ActionCreatorType } from '../types';
import {
    ASYNC_FAIL,
    ASYNC_RESET
} from './types';


/** Creates ASYNC_FAIL action
 ** SID: N/A (General)
*/
export const asyncFail = () => {
    return {
        type: ASYNC_FAIL,
    }
}

/** Creates ASYNC_RESET action
 ** SID: N/A (General)
*/
export const asyncReset = () => {
    return {
        type: ASYNC_RESET,
    }
}

/** Dispatches asyncFail
 ** SID: N/A (General)
*/
export const asyncFailFunc = (): ActionCreatorType => dispatch => {
    dispatch(asyncFail());
}
import { AnyAction } from 'redux';
import {
    SET_ERROR_ARRAY,
    GOOD_TRUE,
    GOOD_FALSE,
    RESET_PREFERENCE_ALERTS
} from '../actions/types';


export interface ProfileBasicState {
    emailErrors: string[], emailGood: boolean,

    posting_titleErrors: string[], posting_titleGood: boolean,
    posting_student_numberErrors: string[], posting_student_numberGood: boolean,
    degree_typeErrors: string[], degree_typeGood: boolean,
    degree_durationErrors: string[], degree_durationGood: boolean,
    project_skills_requiredErrors: string[], project_skills_requiredGood: boolean,
    project_skills_developedErrors: string[], project_skills_developedGood: boolean,
    learning_priorityErrors: string[], learning_priorityGood: boolean,

    languagesErrors: string[], languagesGood: boolean,

    supervisionErrors: string[], supervisionGood: boolean,
    independenceErrors: string[], independenceGood: boolean,
    tough_loveErrors: string[], tough_loveGood: boolean,
    meeting_time_studentErrors: string[], meeting_time_studentGood: boolean,
    student_idea_receptivenessErrors: string[], student_idea_receptivenessGood: boolean,
    advisor_empathyErrors: string[], advisor_empathyGood: boolean,
    advisor_criticismErrors: string[], advisor_criticismGood: boolean,
    response_timeErrors: string[], response_timeGood: boolean,

    practicalityErrors: string[], practicalityGood: boolean,
    creativityErrors: string[], creativityGood: boolean,
    collaborationErrors: string[], collaborationGood: boolean,
    interdisciplinary_preferenceErrors: string[], interdisciplinary_preferenceGood: boolean,
    learning_willingnessErrors: string[], learning_willingnessGood: boolean,
    hypothesis_focusErrors: string[], hypothesis_focusGood: boolean,
    hypothesis_deviationErrors: string[], hypothesis_deviationGood: boolean,
    evidence_for_pursuitErrors: string[], evidence_for_pursuitGood: boolean,
    evidence_for_confirmationErrors: string[], evidence_for_confirmationGood: boolean,
    quantification_importanceErrors: string[], quantification_importanceGood: boolean,
    statistics_likingErrors: string[], statistics_likingGood: boolean,
    writing_detailedErrors: string[], writing_detailedGood: boolean,
    writing_conciseErrors: string[], writing_conciseGood: boolean,
    presentation_seriousnessErrors: string[], presentation_seriousnessGood: boolean,
    presentation_approachabilityErrors: string[], presentation_approachabilityGood: boolean,
    school_enjoymentErrors: string[], school_enjoymentGood: boolean,
    school_is_workErrors: string[], school_is_workGood: boolean,

    work_enjoymentErrors: string[], work_enjoymentGood: boolean,
    work_life_balanceErrors: string[], work_life_balanceGood: boolean,
    work_time_controlErrors: string[], work_time_controlGood: boolean,
    time_portion_teachingErrors: string[], time_portion_teachingGood: boolean,
    time_portion_coursesErrors: string[], time_portion_coursesGood: boolean,
    time_portion_researchErrors: string[], time_portion_researchGood: boolean,
    onboarding_durationErrors: string[], onboarding_durationGood: boolean,
    conference_l_frequencyErrors: string[], conference_l_frequencyGood: boolean,
    conference_i_frequencyErrors: string[], conference_i_frequencyGood: boolean,
    scholarships_timeErrors: string[], scholarships_timeGood: boolean,
    lab_by_routineErrors: string[], lab_by_routineGood: boolean,
    lab_by_taskErrors: string[], lab_by_taskGood: boolean,
    publishing_frequencyErrors: string[], publishing_frequencyGood: boolean,

    lab_collaborationErrors: string[], lab_collaborationGood: boolean,
    lab_friendlinessErrors: string[], lab_friendlinessGood: boolean,
    lab_meeting_durationErrors: string[], lab_meeting_durationGood: boolean,
    lab_meeting_frequencyErrors: string[], lab_meeting_frequencyGood: boolean,
    lab_social_frequencyErrors: string[], lab_social_frequencyGood: boolean,
    lab_ageErrors: string[], lab_ageGood: boolean,
    lab_student_numberErrors: string[], lab_student_numberGood: boolean,
    lab_postdoctorate_numberErrors: string[], lab_postdoctorate_numberGood: boolean,

    grantsErrors: string[], grantsGood: boolean,
    resumesErrors: string[], resumesGood: boolean,
    [index: string]: string[] | boolean
}

const initialState: ProfileBasicState = {
    emailErrors: [], emailGood: false,

    posting_titleErrors: [], posting_titleGood: false,
    posting_student_numberErrors: [], posting_student_numberGood: false,
    degree_typeErrors: [], degree_typeGood: false,
    degree_durationErrors: [], degree_durationGood: false,
    project_skills_requiredErrors: [], project_skills_requiredGood: false,
    project_skills_developedErrors: [], project_skills_developedGood: false,
    learning_priorityErrors: [], learning_priorityGood: false,

    languagesErrors: [], languagesGood: false,

    supervisionErrors: [], supervisionGood: false,
    independenceErrors: [], independenceGood: false,
    tough_loveErrors: [], tough_loveGood: false,
    meeting_time_studentErrors: [], meeting_time_studentGood: false,
    student_idea_receptivenessErrors: [], student_idea_receptivenessGood: false,
    advisor_empathyErrors: [], advisor_empathyGood: false,
    advisor_criticismErrors: [], advisor_criticismGood: false,
    response_timeErrors: [], response_timeGood: false,

    practicalityErrors: [], practicalityGood: false,
    creativityErrors: [], creativityGood: false,
    collaborationErrors: [], collaborationGood: false,
    interdisciplinary_preferenceErrors: [], interdisciplinary_preferenceGood: false,
    learning_willingnessErrors: [], learning_willingnessGood: false,
    hypothesis_focusErrors: [], hypothesis_focusGood: false,
    hypothesis_deviationErrors: [], hypothesis_deviationGood: false,
    evidence_for_pursuitErrors: [], evidence_for_pursuitGood: false,
    evidence_for_confirmationErrors: [], evidence_for_confirmationGood: false,
    quantification_importanceErrors: [], quantification_importanceGood: false,
    statistics_likingErrors: [], statistics_likingGood: false,
    writing_detailedErrors: [], writing_detailedGood: false,
    writing_conciseErrors: [], writing_conciseGood: false,
    presentation_seriousnessErrors: [], presentation_seriousnessGood: false,
    presentation_approachabilityErrors: [], presentation_approachabilityGood: false,
    school_enjoymentErrors: [], school_enjoymentGood: false,
    school_is_workErrors: [], school_is_workGood: false,

    work_enjoymentErrors: [], work_enjoymentGood: false,
    work_life_balanceErrors: [], work_life_balanceGood: false,
    work_time_controlErrors: [], work_time_controlGood: false,
    time_portion_teachingErrors: [], time_portion_teachingGood: false,
    time_portion_coursesErrors: [], time_portion_coursesGood: false,
    time_portion_researchErrors: [], time_portion_researchGood: false,
    onboarding_durationErrors: [], onboarding_durationGood: false,
    conference_l_frequencyErrors: [], conference_l_frequencyGood: false,
    conference_i_frequencyErrors: [], conference_i_frequencyGood: false,
    scholarships_timeErrors: [], scholarships_timeGood: false,
    lab_by_routineErrors: [], lab_by_routineGood: false,
    lab_by_taskErrors: [], lab_by_taskGood: false,
    publishing_frequencyErrors: [], publishing_frequencyGood: false,

    lab_collaborationErrors: [], lab_collaborationGood: false,
    lab_friendlinessErrors: [], lab_friendlinessGood: false,
    lab_meeting_durationErrors: [], lab_meeting_durationGood: false,
    lab_meeting_frequencyErrors: [], lab_meeting_frequencyGood: false,
    lab_social_frequencyErrors: [], lab_social_frequencyGood: false,
    lab_ageErrors: [], lab_ageGood: false,
    lab_student_numberErrors: [], lab_student_numberGood: false,
    lab_postdoctorate_numberErrors: [], lab_postdoctorate_numberGood: false,

    grantsErrors: [], grantsGood: false,
    resumesErrors: [], resumesGood: false,
}

export default function profileBasic(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SET_ERROR_ARRAY:
            return {
                ...state,
                [action.payload.ErrorsName]: action.payload.newErrors
            };
        case GOOD_TRUE:
            return {
                ...state,
                [action.payload]: true
            };
        case GOOD_FALSE:
            return {
                ...state,
                [action.payload]: false
            };
        case RESET_PREFERENCE_ALERTS:
            return initialState
        default:
            return state;
    }
}
import { AnyAction } from 'redux';
import { PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAIL,
        PASSWORD_REQUEST_RESET_SUCCESS, PASSWORD_REQUEST_RESET_FAIL ,
        PASSWORD_CHANGE_SUCCESS, PASSWORD_CHANGE_FAIL
    } from '../actions/types';

export interface AuthenticationPasswordState {
    successfulReset: boolean | null,
    successfulChange: boolean | null,
    successfulRequest: boolean | null,
}

const initialState: AuthenticationPasswordState = {
    successfulReset: null,
    successfulChange: null,
    successfulRequest: null,
}

export default function authenticationPassword(state = initialState, action: AnyAction) {
    switch(action.type) {
        case PASSWORD_REQUEST_RESET_SUCCESS:
            return {
                ...state,
                successfulRequest: true
            }
        case PASSWORD_REQUEST_RESET_FAIL:
            return {
                ...state,
                successfulRequest: false
            }
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                successfulReset: true
            }
        case PASSWORD_RESET_FAIL:
            return {
                ...state,
                successfulReset: false
            }
        case PASSWORD_CHANGE_SUCCESS:
            return {
                ...state,
                successfulChange: true
            }
        case PASSWORD_CHANGE_FAIL:
            return {
                ...state,
                successfulChange: false
            }
        default:
            return state;
    }
}
import { AnyAction } from 'redux';
import { INACTIVATE_TAB, ACTIVATE_TAB, SET_SUB } from '../actions/types';

export interface TabVisibilityState {
    isActive: boolean,
    activeSubs : {[key: string]: number}
}

const initialState: TabVisibilityState = {
    isActive: true,
    activeSubs: {}
}

export default function tabVisibility(state = initialState, action: AnyAction) {
    switch(action.type) {
        case INACTIVATE_TAB:
            return {
                ...state,
                isActive: false
            }
        case ACTIVATE_TAB:
            return {
                ...state,
                isActive: true
            }
        case SET_SUB:
            return {
                ...state,
                activeSubs: {
                    ...state.activeSubs,
                    [action.payload.name]: action.payload.id
                }
            }
        default:
            return state;
    }
}
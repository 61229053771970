// View: React and Router-DOM
import ReactDOM from 'react-dom';


const Modal = ({ children }: { children: JSX.Element}) => {
    return ReactDOM.createPortal(
        children,
        document.getElementById('modal-root') as HTMLElement,
    );
}

export default Modal
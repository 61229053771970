import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../../hooks";
import { BaseButton } from "../../buttons/BaseButton";
import useSlider from "../../slider/useSlider";
// icons
import { ReactComponent as ExpandIcon } from 'bootstrap-icons/icons/arrows-expand.svg';
import { ReactComponent as TocIcon } from 'bootstrap-icons/icons/list-nested.svg';
import { ReactComponent as CollapseIcon } from 'bootstrap-icons/icons/arrows-collapse.svg';
import { ICON_SIZE_LARGE, ICON_SIZE_SMALL } from "../../../globalConstants";
import { useLocationIsProfile } from "./hooks";
import { SIDE_BAR_NAVS } from "./constants";
import { NavLink } from "react-router-dom";
import TableOfContentsAutomatic from "../../common/TableOfContentsAutomatic";
import Modal from "../../modal";


const DASHBOARD_BREAKPOINT_SIZE = 150;


const ThinSideBarToc = () => {
    const [showToc, setShowToc] = useState(false);
    const thinSideBarToc = useRef<HTMLDivElement>(null);

    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    useEffect(() => {
        if (thinSideBarToc && thinSideBarToc.current) {
            const elBoundingRect = thinSideBarToc.current.getBoundingClientRect();
            const elLeft = elBoundingRect.left;
            const elTop = elBoundingRect.top;
            setTop(elTop);
            setLeft(elLeft + 100);
        }
    }, [])

    return (
        <div ref={thinSideBarToc}>
            <BaseButton
                handleAction={() => setShowToc(!showToc)}
                className="btn btn-alternative w-100 py-3"
                icon={<TocIcon width={ICON_SIZE_SMALL} height={ICON_SIZE_SMALL}  title="Contents" />}
                ariaLabel={showToc ? "hide table of contents" : "show table of contents"}
                ariaExpanded={showToc}
                ariaHasPopUp="menu"
            />
            {showToc?
                <Modal>
                    <div
                        role="menu"
                        id="toc-modal"
                        className="highest-z"
                        style={{"top": `${top}px`, "left": `${left}px`}}
                    >
                        <TableOfContentsAutomatic
                            hidden={false}
                        />
                    </div>
                </Modal>
            :
                <TableOfContentsAutomatic
                    hidden={true}
                />
            }
        </div>
    )
}

const SideBarLargerScreen = () => {

    const [sideBarWidth, setSideBarWidth] = useState(300);  
    const { width, ui: SliderUi } = useSlider({ initialWidth: 300 });
    const thinSideBar = sideBarWidth < DASHBOARD_BREAKPOINT_SIZE;

    useEffect(() => {
        setSideBarWidth(width)
    }, [width])

    const [sideBarHeight, setSideBarHeight] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(50);
    const windowSize = useWindowSize();    
    useEffect(() => {
        const getHeaderHeightToChangeSideBarHeight = (header: HTMLElement) => {
            const actualHeaderHeight = header.getBoundingClientRect().height;
            const sideBarHeight = window.innerHeight - actualHeaderHeight;
            setHeaderHeight(actualHeaderHeight);
            setSideBarHeight(sideBarHeight);
        }

        // Select the node that will be observed for mutations
        const header = document.getElementById('header');
        const config = { attributes: true, childList: true, subtree: true };
        let observer: MutationObserver;
        if (header) {
            observer = new MutationObserver(() => getHeaderHeightToChangeSideBarHeight(header));
            observer.observe(header, config);
    
            // run once incase window resized but header height did not change
            getHeaderHeightToChangeSideBarHeight(header)
        }


        return () => {
            if (observer && observer.disconnect) { observer.disconnect() }
        }
    }, [windowSize.height])
    
    const sideBarStyle = {
        "width": `${sideBarWidth}px`,
        "maxHeight": `${sideBarHeight}px`,
        "top": `${headerHeight/2}px`
    };

    const locationIsProfilePages = useLocationIsProfile();

    return (
        <nav
            className="second-lowest-z"
            style={sideBarStyle}
            id="sidebar"
        >
            {thinSideBar ? 
                <BaseButton
                    handleAction={() => setSideBarWidth(Math.floor(window.innerWidth)/4)}
                    className="btn btn-alternative rounded-0 w-100"
                    icon={<ExpandIcon width={ICON_SIZE_SMALL} height={ICON_SIZE_SMALL}  className="rotate-icon" title="Expand" />}
                    ariaLabel="set width to high"
                />
            :
                <BaseButton
                    handleAction={() => setSideBarWidth(DASHBOARD_BREAKPOINT_SIZE /2)}
                    className="btn btn-alternative rounded-0 w-100"
                    icon={<CollapseIcon width={ICON_SIZE_SMALL} height={ICON_SIZE_SMALL}  className="rotate-icon" title="Collapse" />}
                    ariaLabel="set width to low"
                />
            }

            <div id="sidebar-lg-main">
                <div id="sidebar-lg-content">
                    {locationIsProfilePages ?
                        <nav>
                            <ul className="sidebar-lg-navlinks">
                                {SIDE_BAR_NAVS.map((sideBarNav) => {
                                    return (
                                        <li>
                                            <NavLink to={sideBarNav.url} className={"sideBar-navlink navigation-colors" + (thinSideBar ? " justify-content-center": "")} activeClassName="active">
                                                <sideBarNav.icon title={sideBarNav.title} height={ICON_SIZE_LARGE} width={ICON_SIZE_LARGE} className={thinSideBar ? undefined : "me-3"}/>
                                                {thinSideBar ? null : sideBarNav.title}
                                                {<sideBarNav.extra />}
                                            </NavLink>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    :
                        thinSideBar ? 
                            <ThinSideBarToc />
                        :                        
                            <TableOfContentsAutomatic
                                hidden={false}
                            />
                    }
                </div>

                <SliderUi />
            </div>
        </nav>
    )
}

export default SideBarLargerScreen
import { useEffect, useState } from "react";
import { useWindowScrollYPosition } from ".";

export function useElementScrollYPosition(el: HTMLElement) {
    const [scrollPosition, setPosition] = useState(0);

    useEffect(() => {
        function updatePosition() {
            setPosition(el.scrollTop);
        }
        if (el != null) {
            el.addEventListener('scroll', updatePosition);
        }
        return () => {
            if (el != null) {
                el.removeEventListener('scroll', updatePosition);
            }
        }
    });

    return scrollPosition;
}

export function useElementScrollXPosition(el: HTMLElement) {
    const [scrollPosition, setPosition] = useState(0);

    useEffect(() => {
        function updatePosition() {
            setPosition(el.scrollLeft);
        }
        if (el != null) {
            el.addEventListener('scroll', updatePosition);
        }
        return () => {
            if (el != null) {
                el.removeEventListener('scroll', updatePosition);
            }
        }
    });

    return scrollPosition;
}


export function useElementTop(el: HTMLElement | null) {
    const scrollPosition = useWindowScrollYPosition();
    const [pixelsToTop, setPixelsToTop] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (el) {
            const rect = el.getBoundingClientRect();
            setPixelsToTop(rect.top)
        }
    }, [el, scrollPosition])

    return pixelsToTop
}

// initially used this to monitor is user scrolled to bottom of page. (to load more stuff). Didn't use it because pixelsToBottom ended up becoming stale.
export function useElementBottom(el: HTMLElement  | undefined) {
    const scrollPosition = useWindowScrollYPosition();
    const [pixelsToBottom, setPixelsToBottom] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (el) {
            const rect = el.getBoundingClientRect();
            setPixelsToBottom(rect.bottom)
        }
    }, [el, scrollPosition])

    return pixelsToBottom
}

export function useElementIsInViewport(el: HTMLElement | null) {
    let isInViewport = false;
    const pixelsToTop = useElementTop(el)

    if (pixelsToTop != null) {
        const windowHeight = window.innerHeight;
        const isNotBelowWindow = pixelsToTop < (windowHeight);
        const isNotAboveWindow = pixelsToTop > 0;
        if (isNotBelowWindow && isNotAboveWindow) {
            isInViewport = true;
        }
    }

    return isInViewport
}


export function useElementIsAboveHalfWindow(el: HTMLElement | null) {
    let isAboveEyeLevel = false;
    const pixelsToTop = useElementTop(el);

    if (pixelsToTop != null) {
        const windowHeight = window.innerHeight;
        const isAboveWindowMidpoint = pixelsToTop < (windowHeight/2);
        if (isAboveWindowMidpoint) {
            isAboveEyeLevel = true;
        }
    }

    return isAboveEyeLevel
}
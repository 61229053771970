// View: React and Router-DOM
import React from 'react';
import './ToolTip.scss';

export interface ToolTipPropsType {
    isAboveElement?: boolean,
    isRightJustified?: boolean,
    children: JSX.Element | string
}
export const ToolTip = ({ 
        isRightJustified, isAboveElement,
        children, 
    }: ToolTipPropsType) => {

    let tipStyle: React.CSSProperties = {
        "width": "400px", 
        "maxWidth": "50vw",
    };

    if (isRightJustified) {
        tipStyle["right"] = "0px";
    } else {
        tipStyle["left"] = "0px";
    }
    if (isAboveElement) {
        tipStyle["bottom"] = "100%";
    } else {
        tipStyle["top"] = "100%";
    }

    return (
        <div className="tooltip highest-z position-absolute"
            style={tipStyle}
        >
            {children}
        </div>
    )
}

import { CREATE_ALERT } from './types';
import type { ActionCreatorType } from '../types';


/** Creates an alert using an object
 ** SID: 0,007
*/
export const createAlert = (msg: object, status: number = 200) => {
    return {
        type: CREATE_ALERT,
        payload: { msg, status }
    }
}

/** Creates an error alert using a string
 ** SID: 0,007
*/
export const createDropdownError = (message = 'hello'): ActionCreatorType => (dispatch) => {
    dispatch(createAlert({ error: message }, 9999));
};


/** Creates an error alert using a string
 ** SID: ?
*/
export const createDropdownSuccess = (message = 'hello'): ActionCreatorType => (dispatch) => {
    dispatch(createAlert({ watever: message }, 200));
};

import { CancelTokenSource } from 'axios';
import { AnyAction } from 'redux';
import { ThunkDispatch} from 'redux-thunk';
import { ErrorMessageType } from '../components/ErrorHandlers';
import { ReduxState } from '../reducers';

export type UserTypeType = "supervisor" | "student" | null;
export type DesiredUserTypeType = "supervisors" | "students" | undefined;

/** Do something mean on the initiating component */
export type HandleBackendErrorsType = (msgObject: ErrorMessageType, arg2?: any) => void
/** Do something nice on the initiating component */
export type HandleBackendSuccessType<Type = any> = (arg1: Type) => void

export type DispatchType = ThunkDispatch<ReduxState, unknown, AnyAction>
export type ActionCreatorType = {
  (
    dispatch: DispatchType,
    getState: () => ReduxState
  ): void;
}

export type CancelableActionCreator = {
  (
    dispatch: DispatchType,
    getState: () => ReduxState
  ): CancelTokenSource;
  cancel?: () => void;
}

export function JsonCopy<Type>(value: Type): Type {
  return JSON.parse(JSON.stringify(value));
}

export type PaginatedData<Type> = {
  next: number,
  previous: number,
  count: number,
  results: Type[]
}
import { AnyAction } from 'redux';
import { 
    REGISTER_SUCCESS,
    REGISTER_FAIL, 
} from '../actions/types';


export interface GradlySignupState {
    successfulRegister: boolean
}


const initialState: GradlySignupState = {
    successfulRegister: false,
}


export default function gradlySignup(state = initialState, action: AnyAction) {
    switch (action.type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                successfulRegister: true,
            }
        case REGISTER_FAIL:
            return {
                ...state,
                successfulRegister: false,
            };
        default:
            return state;
    }
}
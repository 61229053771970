// View: React
import React, { Fragment, useEffect} from 'react';
// State: React-Redux
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';

import logo from '../../icons/gradsun512.svg';

// If these are within the function body, the originalHref will refer to a canvased image
let favicon = document.getElementById('favicon') as HTMLLinkElement;
const originalHref = favicon.href;

/** Change Favicon when making async calls.
 ** SID: 0,009 Show spinner during API calls
 * @author Nassir Al-Khishman 
*/
export const DynamicFavicon = ({
        /** 100 - 150 ms is smoothest on chrome 2021 Jan 10. 
         * Too fast ends up looking jittery because of lag in href update (canvas itself does jitter).
         * default WAS 30 because longest request is about 3s (3s = 3000ms; n = 100; 3000ms/100 = 30 ms)
         * default is 110 because now n is being incremented by four every interval. This was done to reduce the number of intervals
        */
        timeUpdateAnimation = 110, 
        /** If the box within the lines is 100px in each direction, the width and height will be 70px each*/
        logoProportionOfBox = 0.95
    }) => {
    

    const isSpinning = useSelector((state: ReduxState) => state.spinner.isSpinning);
    const asyncFailed = useSelector((state: ReduxState) => state.errors.asyncFailed);

    const canvasWidth = 320;
    const canvasHeight = 320;

    useEffect(() => {
        let animateFaviconInterval: number | undefined = undefined;
        let asyncFailedTimeout: number | undefined = undefined;

        const canvas = document.getElementById('canvas') as HTMLCanvasElement;
        let context = canvas.getContext("2d");

        if (context != null) {

            // line width and margins
            const lineWidth = canvasWidth/10;
            const halfOfLineWidth = lineWidth/2;
            const rightPixelsCroppedFromCanvasByFavicon = lineWidth/2; // for some reason, favicon crops the right side at a smaller width. The canvas itself looks fine.

            // line drawing points
            const leftPoint = halfOfLineWidth;
            const rightPoint = canvasWidth - halfOfLineWidth - rightPixelsCroppedFromCanvasByFavicon;
            const topPoint = halfOfLineWidth;
            const bottomPoint = canvasHeight - halfOfLineWidth;

            // logo size and position
            const logoWidth = (canvasWidth - (lineWidth * 2) - rightPixelsCroppedFromCanvasByFavicon) * logoProportionOfBox;
            const logoHeight = (canvasWidth - (lineWidth * 2)) * logoProportionOfBox;
            const logoOffsetX = canvasWidth/2 - logoWidth/2 - lineWidth/4;
            const logoOffsetY = canvasWidth/2 - logoWidth/2 - lineWidth/4;

            // first: clear canvas
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            // second: insert logo
            const logoImage = document.getElementById('gradsun-logo') as HTMLImageElement;
            if (logoImage.complete) {
                context.drawImage(logoImage, logoOffsetX, logoOffsetY, logoWidth, logoHeight);
            }

            // third: set styling for line
            context.lineWidth = lineWidth;

            if (isSpinning) {
                context.strokeStyle = "#ffd35c";

                // initiate animation
                let n = 0;
                let isPaused = false;
                animateFaviconInterval = window.setInterval(async () => {
                    if (context && !isPaused) {
                        // start at origin
                        context.beginPath();
                        context.moveTo(leftPoint, topPoint);
        
                        // seperate animation based on which lines have already been drawn
                        if (n <= 25) { 
                            context.lineTo(rightPoint * (n/25), topPoint);
                        } else if (n > 25 && n <= 50) { 
                            context.lineTo(rightPoint, topPoint);
                            context.lineTo(rightPoint, bottomPoint * ((n-25)/25));
                        } else if (n > 50 && n <= 75) { 
                            context.lineTo(rightPoint, topPoint);
                            context.lineTo(rightPoint, bottomPoint);
                            context.lineTo(leftPoint - (rightPoint*((n-75)/25)), bottomPoint);
                        } else if (n > 75 && n <= 100) {
                            context.lineTo(rightPoint, topPoint);
                            context.lineTo(rightPoint, bottomPoint);
                            context.lineTo(leftPoint, bottomPoint);
                            context.lineTo(leftPoint, - (bottomPoint*((n-100)/25)));
                        }

                        /** restart animation after it reaches end
                         * make up for steps: first, second, and fourth
                        */
                        if (n >= 100) {
                            // wait to enjoy the full square
                            isPaused = true;
                            // first: clear canvas 
                            context.clearRect(0, 0, canvasWidth, canvasHeight);
                            context.beginPath(); // this is required for removing lines
                            // second: (insert logo)
                            if (logoImage.complete) {
                                context.drawImage(logoImage, logoOffsetX, logoOffsetY, logoWidth, logoHeight);
                            }
                            // restart animation
                            n = 0;
                        } else {
                            // next
                            n++;
                            n++;
                            n++;
                            n++;
                        }

                        // draw and set to favicon
                        context.stroke();
                        favicon.href = canvas.toDataURL('image/icon');
                        
                    } else if (isPaused) {
                        // wait to enjoy the full square
                        // note that the interval will keep creating these promises and putting this in que 
                        // along with the intervals that follow
                        await new Promise(function(resolve, reject) {
                            window.setTimeout(function() {
                                resolve('done!');
                            }, 1000)
                        });
                        isPaused = false;
                    }
                }, timeUpdateAnimation)
            } else if (asyncFailed) {
                clearInterval(animateFaviconInterval);
                // red if failed
                context.strokeStyle = "red";

                // start at origin
                context.beginPath();
                context.moveTo(leftPoint, topPoint);
                context.lineTo(rightPoint, topPoint);
                context.lineTo(rightPoint, bottomPoint);
                context.lineTo(leftPoint, bottomPoint);
                context.lineTo(leftPoint, topPoint - halfOfLineWidth);

                // draw and set to favicon
                context.stroke();
                favicon.href = canvas.toDataURL('image/icon');

                // turn back to original after 5s
                asyncFailedTimeout = window.setTimeout(() => {
                    favicon.href = originalHref;
                }, 5000)
            } else {
                clearInterval(animateFaviconInterval);
                favicon.href = originalHref;
            }
        } else {
            clearInterval(animateFaviconInterval);
            favicon.href = originalHref;
        }

        return function cleanup() {
            clearInterval(animateFaviconInterval);
            clearTimeout(asyncFailedTimeout);            
        }
    });

    return (
        <Fragment>
            <canvas width={canvasWidth} height={canvasHeight} id="canvas"
                style={{border: "1px solid #d3d3d3"}}
                hidden
                data-testid="favicon-canvas"
            />
            <img hidden id="gradsun-logo" src={logo} alt="Gradsun"/>
        </Fragment>
    );
}

export default DynamicFavicon;
// View: React
import React, { Fragment } from 'react';
// Components
import { EmptyButton, EmptyButtonPropsType} from './EmptyButton';
import ModernSpinner from '../forms_basic/ModernSpinner';


interface BaseButtonPropsType extends EmptyButtonPropsType {
    /** Icon.*/
    icon?: JSX.Element,
    /** Whether icon is at the start or the end */
    iconStart?: boolean,
    /** Show the spinning icon. */
    isProcessing?: boolean,
}
/** Button with text and an icon.
 ** SID: N/A (general)
 * @author Nassir Al-Khishman 
*/
export const BaseButton = ({
        show = true,
        handleAction, actionArgs = [],
        className = "btn btn-main",
        id = undefined,
        disabled = false,
        ariaLabel = undefined, ariaHasPopUp = undefined, ariaExpanded = undefined,
        children = undefined,
        hint = undefined, modalHint = false, rightJustified = false,
        icon = undefined, iconStart = false,
        isProcessing = false
    }: BaseButtonPropsType) => {
    
    const iconClassName = [
        'd-flex flex-nowrap align-items-center', 
    ].join(' ').trim();

    return (
        <EmptyButton
            show={show}
            handleAction={handleAction}
            actionArgs={actionArgs}
            className={className}
            id={id}
            disabled={disabled}
            ariaLabel={ariaLabel}
            ariaExpanded={ariaExpanded}
            ariaHasPopUp={ariaHasPopUp}
            hint={hint} modalHint={modalHint} rightJustified={rightJustified}
        >
            <div className="col-12 d-flex align-items-center justify-content-center">
                {isProcessing ? 
                    <ModernSpinner />
                :
                    <Fragment>                    
                        {iconStart && icon != null ?
                            <div className={iconClassName + (children ? " me-2" : "")}>
                                {icon}
                            </div>
                        :
                            null
                        }
                        <div>
                            {children}
                        </div>
                        {!iconStart && icon != null ?
                            <div className={iconClassName + (children ? " ms-2" : "")}>
                                {icon}
                            </div>
                        :
                            null
                        }
                    </Fragment>
                }
            </div>
        </ EmptyButton>
    )
};

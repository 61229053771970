// View: React
import React, { useState } from 'react'
import { ToolTip } from '../tooltips/ToolTip';
import { ToolTipModal } from '../tooltips/ToolTipModal';


export interface EmptyButtonPropsType {
    /** Determines whether to show.*/
    show?: boolean,
    /** Action executed by button.*/
    handleAction: (arg1?: any) => void,
    /** Action arguments, if any.*/
    actionArgs?: any,
    /** Button class.*/
    className?: string,
    /** Button ID.*/
    id?: string,
    /** Whether button is disabled.*/
    disabled?: boolean,
    /** Describe button when there is no text.*/
    ariaLabel?: string | undefined,
    /** whether the button is responsible for collapsing/expanding */
    ariaHasPopUp?: "menu" | "dialog" | "listbox",
    /** Whether the button's target is currently collapsed/expanded */
    ariaExpanded?: boolean,
    /** Button contents/text.*/
    children?: JSX.Element | JSX.Element[] | null | string,
    /** show tooltip on hover */
    hint?: JSX.Element | null,
    modalHint?: boolean,
    /** Positioning of tooltip */
    rightJustified?: boolean,
};
/** Base button.
 ** SID: N/A (general)
 * @author Nassir Al-Khishman 
*/
export const EmptyButton = ({ 
        show = true,
        handleAction, actionArgs = [],
        className = "btn btn-main",
        id = undefined,
        disabled = false,
        ariaLabel = undefined, ariaHasPopUp = undefined, ariaExpanded = undefined,
        children = null,
        hint = null, modalHint = false, rightJustified = false,
    }: EmptyButtonPropsType) => {

    const [showHint, setShowHint] = useState(false);

    if (!show) {
        return null;
    }

    return (
        // Had to add this div because onmouse enter isn't trigerred when button is disasbled
        <div
            className="d-flex w-100"
            onMouseEnter={() => setShowHint(true)}
            onMouseLeave={() => setShowHint(false)}
        >
            <button
                type="button"
                onClick={() => handleAction(actionArgs)}
                className={className + " position-relative"}
                id={id}
                disabled={disabled}
                aria-label={ariaLabel}
                aria-haspopup={ariaHasPopUp}
                aria-expanded={ariaExpanded}

            >
                {children}
                {hint && showHint ?
                    modalHint ?
                        <ToolTipModal isRightJustified={rightJustified}>
                            {hint}
                        </ToolTipModal>
                    :
                        <ToolTip isRightJustified={rightJustified}>
                            {hint}
                        </ToolTip>
                :
                    null
                }
            </button>
        </div>
    )
};

export default EmptyButton
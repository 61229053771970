// View: React
import React, { ErrorInfo } from 'react';
// Components
import ErrorComponent from './ErrorComponent';


type ErrorBoundaryPropsType = {
    children: JSX.Element | JSX.Element[];
}


type ErrorBoundaryState = {
    hasError: boolean
}

/** TODO: an error boundary
 ** SID: N/A (general)
 * @author Nassir Al-Khishman 
*/
export class ErrorBoundary extends React.Component<ErrorBoundaryPropsType, ErrorBoundaryState> {
    state = { 
        /** Show the error component */
        hasError: false 
    }

    static getDerivedStateFromError(_: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorComponent />
        }

        return this.props.children; 
    }
}

export default ErrorBoundary
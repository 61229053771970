import { AnyAction } from 'redux';
import { DetailedConversationDataType, FullConversationDataType } from '../actions/messenger';
import { 
    GET_CONVERSATION_TRACKER,
    CLEAR_CURRENTLY_SELECTED_CONVERSATION,
    SET_CURRENTLY_SELECTED_CONVERSATION,
    SET_FULL_CONVERSATION,
    CONVERSATION_DOES_NOT_EXIST,
    SET_POSTING_TITLE
} from '../actions/types';


export type ReduxConversationDataType = DetailedConversationDataType & FullConversationDataType

export interface MessengerState {
    unreadMessagesCount: number,
    currentlySelectedConversation: ReduxConversationDataType,
    conversationDoesNotExist: boolean,
    isLoading: boolean,
}

const initialCurrentlySelectedConversation: ReduxConversationDataType = {
    id: 0,
    unread_count_supervisor: 0, 
    unread_count_student: 0,
    last_message_creation_time: "",
    last_message_text: "",
    last_message_sender: 0,
    project: 0, 
    student: 0,
    messages: [],
    /** posting_title is from 'DetailedConversationDataType. */
    posting_title: "",
}

const initialState: MessengerState = {
    unreadMessagesCount: 0,
    currentlySelectedConversation: initialCurrentlySelectedConversation,
    conversationDoesNotExist: true,
    isLoading: true,
};

export default function messenger(state = initialState, action: AnyAction) {
    switch(action.type) {
        case GET_CONVERSATION_TRACKER:
            return {
                ...state,
                unreadMessagesCount: action.payload.unread_messages_count
            }
        case CLEAR_CURRENTLY_SELECTED_CONVERSATION:
            return {
                ...state,
                isLoading: false,
                conversationDoesNotExist: true,
                currentlySelectedConversation: initialCurrentlySelectedConversation
            }
        case SET_CURRENTLY_SELECTED_CONVERSATION:
            return {
                ...state,
                isLoading: true,
                conversationDoesNotExist: false,
                currentlySelectedConversation: {
                    ...initialCurrentlySelectedConversation,
                    ...action.payload
                }
            }
        case SET_FULL_CONVERSATION:
            const data = action.payload as FullConversationDataType
            // only modify if conversation is currently selected
            if (state.currentlySelectedConversation.project === data.project && state.currentlySelectedConversation.student === data.student) {
                return {
                    ...state,
                    isLoading: false,
                    conversationDoesNotExist: false,
                    currentlySelectedConversation: {
                        posting_title: state.currentlySelectedConversation.posting_title,
                        ...data
                    }
                }
            } else {
                return state
            }
        case CONVERSATION_DOES_NOT_EXIST:
            return {
                ...state,
                isLoading: false,
                conversationDoesNotExist: true
            }
        case SET_POSTING_TITLE:
            return {
                ...state,
                currentlySelectedConversation: {
                    ...state.currentlySelectedConversation,
                    posting_title: action.payload
                }
            }
        default:
            return state;
    }
}